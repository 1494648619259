export const LISTA_FINANCEIRO_CATEGORY = 'LISTA_FINANCEIRO_CATEGORY';
export const LISTA_FINANCEIRO_CATEGORY_SUCCESSFUL =
  'LISTA_FINANCEIRO_CATEGORY_SUCCESSFUL';
export const CADASTRAR_FINANCEIRO_CATEGORY = 'CADASTRAR_FINANCEIRO_CATEGORY';
export const CADASTRAR_FINANCEIRO_CATEGORY_SUCCESSFUL =
  'CADASTRAR_FINANCEIRO_CATEGORY_SUCCESSFUL';
export const EDITAR_FINANCEIRO_CATEGORY = 'EDITAR_FINANCEIRO_CATEGORY';
export const EDITAR_FINANCEIRO_CATEGORY_SUCCESSFUL =
  'EDITAR_FINANCEIRO_CATEGORY_SUCCESSFUL';
export const DELETAR_FINANCEIRO_CATEGORY = 'DELETAR_FINANCEIRO_CATEGORY';
export const DELETAR_FINANCEIRO_CATEGORY_SUCCESSFUL =
  'DELETAR_FINANCEIRO_CATEGORY_SUCCESSFUL';
export const VIEW_FINANCEIRO_CATEGORY = 'VIEW_FINANCEIRO_CATEGORY';
export const VIEW_FINANCEIRO_CATEGORY_SUCCESSFUL =
  'VIEW_FINANCEIRO_CATEGORY_SUCCESSFUL';
