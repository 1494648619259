import {
  VIEW_FINANCEIRO_EXTRACT,
  VIEW_FINANCEIRO_EXTRACT_SUCCESSFUL,
  VIEW_FINANCEIRO_EXTRACT_ACCOUNTS,
  VIEW_FINANCEIRO_EXTRACT_ACCOUNTS_SUCCESSFUL,
  LIST_FINANCEIRO_EXTRACT,
  LIST_FINANCEIRO_EXTRACT_SUCCESSFUL,
  GRAPHIC_FINANCEIRO_EXTRACT_SUCCESSFUL,
  GRAPHIC_FINANCEIRO_EXTRACT,
} from './actionTypes';

const INIT_STATE = {
  error: '',
  extract: {
    data: {
      result: [],
      pagination: {
        start: 0,
        end: 0,
        total: 0,
      },
    },
    isLoading: true,
  },
  flow: {
    data: {
      result: {
        acumulado: {
          saldo: 0,
          previsao: 0,
        },
        receitas: {
          saldo: 0,
          previsao: 0,
        },
        despesas: {
          saldo: 0,
          previsao: 0,
        },
      },
    },
    isLoading: true,
  },
  graphic: {
    data: [
      {
        id: 'Receita:',
        color: '#28a745',
        data: [],
      },
      {
        id: 'Despesa:',
        color: '#dc3545',
        data: [],
      },
    ],
    status: false,
    isLoading: true,
  },
  accounts: {
    data: {
      result: [],
    },
    isLoading: true,
  },
};

const FinanceExtract = (state = INIT_STATE, action) => {
  switch (action.type) {
    case VIEW_FINANCEIRO_EXTRACT:
      return {
        ...state,
        flow: {
          data: {
            result: {
              acumulado: {
                saldo: 0,
                previsao: 0,
              },
              receitas: {
                saldo: 0,
                previsao: 0,
              },
              despesas: {
                saldo: 0,
                previsao: 0,
              },
            },
          },
          isLoading: true,
        },
      };
    case VIEW_FINANCEIRO_EXTRACT_SUCCESSFUL:
      return {
        ...state,
        flow: {
          data: {
            result: {
              acumulado: {
                saldo: action.payload.result.saldo,
                previsao: action.payload.result.saldo_previsao,
              },
              receitas: {
                saldo: action.payload.result.receita,
                previsao: action.payload.result.receita_previsao,
              },
              despesas: {
                saldo: action.payload.result.despesa,
                previsao: action.payload.result.despesa_previsao,
              },
            },
          },
          isLoading: false,
        },
      };
    case VIEW_FINANCEIRO_EXTRACT_ACCOUNTS:
      return {
        ...state,
        accounts: {
          data: {
            result: [],
          },
          isLoading: true,
        },
      };
    case VIEW_FINANCEIRO_EXTRACT_ACCOUNTS_SUCCESSFUL:
      return {
        ...state,
        accounts: {
          data: {
            result: action.payload.result,
          },
          isLoading: false,
        },
      };
    case LIST_FINANCEIRO_EXTRACT:
      return {
        ...state,
        extract: {
          data: {
            result: [],
            pagination: {
              start: 0,
              end: 0,
              total: 0,
            },
          },
          isLoading: true,
        },
      };
    case LIST_FINANCEIRO_EXTRACT_SUCCESSFUL:
      return {
        ...state,
        extract: {
          data: {
            result: action.payload.result,
            pagination: action.payload.pagination,
          },
          isLoading: false,
        },
      };
    case GRAPHIC_FINANCEIRO_EXTRACT:
      return {
        ...state,
        graphic: {
          data: [
            {
              id: 'Receita:',
              color: '#28a745',
              data: [],
            },
            {
              id: 'Despesa:',
              color: '#dc3545',
              data: [],
            },
          ],
          status: false,
          isLoading: true,
        },
      };
    case GRAPHIC_FINANCEIRO_EXTRACT_SUCCESSFUL:
      return {
        ...state,
        graphic: {
          data: [
            {
              id: 'Receita:',
              color: '#28a745',
              data: action.payload.result.receita,
            },
            {
              id: 'Despesa:',
              color: '#dc3545',
              data: action.payload.result.despesa,
            },
          ],
          status: action.payload.status,
          isLoading: false,
        },
      };
    default:
      return { ...state };
  }
};

export default FinanceExtract;
