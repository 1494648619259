export const LISTA_FINANCEIRO_BANKSACCOUNTS = 'LISTA_FINANCEIRO_BANKSACCOUNTS';
export const LISTA_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL =
  'LISTA_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL';
export const LISTA_FINANCEIRO_LISTBANKS = 'LISTA_FINANCEIRO_LISTBANKS';
export const LISTA_FINANCEIRO_LISTBANKS_SUCCESSFUL =
  'LISTA_FINANCEIRO_LISTBANKS_SUCCESSFUL';
export const CADASTRAR_FINANCEIRO_BANKSACCOUNTS =
  'CADASTRAR_FINANCEIRO_BANKSACCOUNTS';
export const CADASTRAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL =
  'CADASTRAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL';
export const EDITAR_FINANCEIRO_BANKSACCOUNTS =
  'EDITAR_FINANCEIRO_BANKSACCOUNTS';
export const EDITAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL =
  'EDITAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL';
export const DELETAR_FINANCEIRO_BANKSACCOUNTS =
  'DELETAR_FINANCEIRO_BANKSACCOUNTS';
export const DELETAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL =
  'DELETAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL';
export const VIEW_FINANCEIRO_BANKSACCOUNTS = 'VIEW_FINANCEIRO_BANKSACCOUNTS';
export const VIEW_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL =
  'VIEW_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL';
