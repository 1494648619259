import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { VIEW_FINANCEIRO_CASHFLOW } from './actionTypes';
import { ViewFinanceiroCashFlowSuccessful } from './actions';
import { apiError } from '../../actions';

// Include Both Helper File with needed methods
import { getJWT } from '../../../helpers/JWTHelper';

function* ViewFinanceiroCashFlow({
  payload: { year, revenue, expenditure, vencidos, aberto, conta },
}) {
  try {
    const response = yield call(
      getJWT,
      `financial/cashflow/list?year=${year}&receita=${revenue}&despesa=${expenditure}&vencidos=${vencidos}&aberto=${aberto}&conta=${conta}`
    );
    yield put(ViewFinanceiroCashFlowSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchViewFinanceiroCashFlow() {
  yield takeEvery(VIEW_FINANCEIRO_CASHFLOW, ViewFinanceiroCashFlow);
}

function* FinanceCashFlowSagas() {
  yield all([fork(watchViewFinanceiroCashFlow)]);
}

export default FinanceCashFlowSagas;
