import {
  LISTA_FINANCEIRO_COSTCENTER,
  LISTA_FINANCEIRO_COSTCENTER_SUCCESSFUL,
  CADASTRAR_FINANCEIRO_COSTCENTER,
  CADASTRAR_FINANCEIRO_COSTCENTER_SUCCESSFUL,
  EDITAR_FINANCEIRO_COSTCENTER,
  EDITAR_FINANCEIRO_COSTCENTER_SUCCESSFUL,
  DELETAR_FINANCEIRO_COSTCENTER,
  DELETAR_FINANCEIRO_COSTCENTER_SUCCESSFUL,
  VIEW_FINANCEIRO_COSTCENTER,
  VIEW_FINANCEIRO_COSTCENTER_SUCCESSFUL,
} from './actionTypes';

export const ListaFinanceiroCostCenter = (search, page, total) => {
  return {
    type: LISTA_FINANCEIRO_COSTCENTER,
    payload: { search, page, total },
  };
};

export const ListaFinanceiroCostCenterSuccessful = (dados) => {
  return {
    type: LISTA_FINANCEIRO_COSTCENTER_SUCCESSFUL,
    payload: dados,
  };
};

export const CadastrarFinanceiroCostCenter = (nome, tipo) => {
  return {
    type: CADASTRAR_FINANCEIRO_COSTCENTER,
    payload: { nome, tipo },
  };
};

export const CadastrarFinanceiroCostCenterSuccessful = (response) => {
  return {
    type: CADASTRAR_FINANCEIRO_COSTCENTER_SUCCESSFUL,
    payload: response,
  };
};

export const EditarFinanceiroCostCenter = (dados) => {
  return {
    type: EDITAR_FINANCEIRO_COSTCENTER,
    payload: dados,
  };
};

export const EditarFinanceiroCostCenterSuccessful = (response) => {
  return {
    type: EDITAR_FINANCEIRO_COSTCENTER_SUCCESSFUL,
    payload: response,
  };
};

export const DeletarFinanceiroCostCenter = (id) => {
  return {
    type: DELETAR_FINANCEIRO_COSTCENTER,
    payload: id,
  };
};

export const DeletarFinanceiroCostCenterSuccessful = (response) => {
  return {
    type: DELETAR_FINANCEIRO_COSTCENTER_SUCCESSFUL,
    payload: response,
  };
};

export const ViewFinanceiroCostCenter = (id) => {
  return {
    type: VIEW_FINANCEIRO_COSTCENTER,
    payload: id,
  };
};

export const ViewFinanceiroCostCenterSuccessful = (dados) => {
  return {
    type: VIEW_FINANCEIRO_COSTCENTER_SUCCESSFUL,
    payload: dados,
  };
};
