import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  LISTA_FINANCEIRO_CATEGORY,
  CADASTRAR_FINANCEIRO_CATEGORY,
  EDITAR_FINANCEIRO_CATEGORY,
  VIEW_FINANCEIRO_CATEGORY,
  DELETAR_FINANCEIRO_CATEGORY,
} from './actionTypes';
import {
  ListaFinanceiroCategorySuccessful,
  CadastrarFinanceiroCategorySuccessful,
  EditarFinanceiroCategorySuccessful,
  DeletarFinanceiroCategorySuccessful,
  ViewFinanceiroCategorySuccessful,
} from './actions';
import { apiError } from '../../actions';

// Include Both Helper File with needed methods
import { postJWT, getJWT } from '../../../helpers/JWTHelper';

function* GetFinanceiroCategory({ payload: { search, page, total, tipo } }) {
  try {
    const response = yield call(
      getJWT,
      `financial/categories/list?search=${search}&page=${page}&total=${total}&tipo=${tipo}`
    );
    yield put(ListaFinanceiroCategorySuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchListaFinanceiroCategory() {
  yield takeEvery(LISTA_FINANCEIRO_CATEGORY, GetFinanceiroCategory);
}

function* ViewFinanceiroCategory({ payload: id }) {
  try {
    const response = yield call(getJWT, `financial/categories/view/${id}`);
    yield put(ViewFinanceiroCategorySuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchViewFinanceiroCategory() {
  yield takeEvery(VIEW_FINANCEIRO_CATEGORY, ViewFinanceiroCategory);
}

function* CadastrarFinanceiroCategory({ payload: { nome, tipo } }) {
  try {
    const response = yield call(postJWT, `financial/categories/create`, {
      nome,
      tipo,
    });
    yield put(CadastrarFinanceiroCategorySuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCadastrar() {
  yield takeEvery(CADASTRAR_FINANCEIRO_CATEGORY, CadastrarFinanceiroCategory);
}

function* EditarFinanceiroCategory({ payload: dados }) {
  try {
    const response = yield call(postJWT, `financial/categories/update`, {
      id: dados.id,
      nome: dados.nome,
      tipo: dados.tipo,
    });
    yield put(EditarFinanceiroCategorySuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchEditar() {
  yield takeEvery(EDITAR_FINANCEIRO_CATEGORY, EditarFinanceiroCategory);
}

function* DeletarFinanceiroCategory({ payload: id }) {
  try {
    const response = yield call(postJWT, `financial/categories/delete`, {
      id,
    });
    yield put(DeletarFinanceiroCategorySuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchDeletar() {
  yield takeEvery(DELETAR_FINANCEIRO_CATEGORY, DeletarFinanceiroCategory);
}

function* FinanceCategorySagas() {
  yield all([
    fork(watchListaFinanceiroCategory),
    fork(watchViewFinanceiroCategory),
    fork(watchCadastrar),
    fork(watchEditar),
    fork(watchDeletar),
  ]);
}

export default FinanceCategorySagas;
