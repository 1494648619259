import {
  LISTA_CONTAS_RECEBER,
  LISTA_CONTAS_RECEBER_SUCCESSFUL,
  CATEGORIAS_CONTA_RECEBER,
  CATEGORIAS_CONTA_RECEBER_SUCCESSFUL,
  CAIXAS_CONTA_RECEBER,
  CAIXAS_CONTA_RECEBER_SUCCESSFUL,
  CENTROCUSTO_CONTA_RECEBER,
  CENTROCUSTO_CONTA_RECEBER_SUCCESSFUL,
  PACIENTES_CONTA_RECEBER,
  PACIENTES_CONTA_RECEBER_SUCCESSFUL,
  CADASTRAR_CONTA_RECEBER,
  CADASTRAR_CONTA_RECEBER_SUCCESSFUL,
  EDITAR_CONTA_RECEBER,
  EDITAR_CONTA_RECEBER_SUCCESSFUL,
  VIEW_CONTA_RECEBER,
  VIEW_CONTA_RECEBER_SUCCESSFUL,
} from './actionTypes';

const INIT_STATE = {
  error: '',
  contas: {
    data: {
      result: [],
      pagination: {
        start: 0,
        end: 0,
        total: 0,
      },
    },
    isLoading: true,
  },
  categorias: {
    data: {
      result: [],
    },
    isLoading: true,
  },
  caixas: {
    data: {
      result: [],
    },
    isLoading: true,
  },
  centrocusto: {
    data: {
      result: [],
    },
    isLoading: true,
  },
  pacientes: {
    data: {
      result: [],
    },
    isLoading: true,
  },
  contareceber: {
    data: {
      result: [],
    },
    isLoading: true,
  },
  isSave: false,
};

const Revenue = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTA_CONTAS_RECEBER:
      return {
        ...state,
        contas: {
          data: {
            result: [],
            pagination: {
              start: 0,
              end: 0,
              total: 0,
            },
          },
          isLoading: true,
        },
      };
    case LISTA_CONTAS_RECEBER_SUCCESSFUL:
      return {
        ...state,
        contas: {
          data: {
            result: action.payload.result,
            pagination: action.payload.pagination,
          },
          isLoading: false,
        },
      };
    case CATEGORIAS_CONTA_RECEBER:
      return {
        ...state,
        categorias: {
          data: {
            result: [],
          },
          isLoading: true,
        },
        isSave: false,
      };
    case CATEGORIAS_CONTA_RECEBER_SUCCESSFUL:
      return {
        ...state,
        categorias: {
          data: { result: action.payload.result },
          isLoading: false,
        },
      };
    case CAIXAS_CONTA_RECEBER:
      return {
        ...state,
        caixas: {
          data: {
            result: [],
          },
          isLoading: true,
        },
      };
    case CAIXAS_CONTA_RECEBER_SUCCESSFUL:
      return {
        ...state,
        caixas: {
          data: { result: action.payload.result },
          isLoading: false,
        },
      };
    case CENTROCUSTO_CONTA_RECEBER:
      return {
        ...state,
        centrocusto: {
          data: {
            result: [],
          },
          isLoading: true,
        },
      };
    case CENTROCUSTO_CONTA_RECEBER_SUCCESSFUL:
      return {
        ...state,
        centrocusto: {
          data: { result: action.payload.result },
          isLoading: false,
        },
      };
    case PACIENTES_CONTA_RECEBER:
      return {
        ...state,
        pacientes: {
          data: {
            result: [],
          },
          isLoading: true,
        },
      };
    case PACIENTES_CONTA_RECEBER_SUCCESSFUL:
      return {
        ...state,
        pacientes: {
          data: { result: action.payload.result },
          isLoading: false,
        },
      };
    case CADASTRAR_CONTA_RECEBER:
      return {
        ...state,
        isSave: false,
      };
    case CADASTRAR_CONTA_RECEBER_SUCCESSFUL:
      return {
        ...state,
        isSave: true,
      };
    case EDITAR_CONTA_RECEBER:
      return {
        ...state,
        isSave: false,
      };
    case EDITAR_CONTA_RECEBER_SUCCESSFUL:
      return {
        ...state,
        isSave: true,
      };
    case VIEW_CONTA_RECEBER:
      return {
        ...state,
        contareceber: {
          data: {
            result: [],
          },
          isLoading: true,
        },
      };
    case VIEW_CONTA_RECEBER_SUCCESSFUL:
      return {
        ...state,
        contareceber: {
          data: { result: action.payload },
          isLoading: false,
        },
      };
    default:
      return { ...state };
  }
};

export default Revenue;
