import {
  PATIENTS,
  PATIENTS_SUCCESSFUL,
  APPOINTMENTS,
  APPOINTMENTS_SUCCESSFUL,
  DAY_APPOINTMENTS,
  DAY_APPOINTMENTS_SUCCESSFUL,
  GENDER,
  GENDER_SUCCESSFUL,
  MONTHCARE,
  MONTHCARE_SUCCESSFUL,
  INSURANCE,
  INSURANCE_SUCCESSFUL,
  AGE,
  AGE_SUCCESSFUL,
} from './actionTypes';

export const Patients = () => {
  return {
    type: PATIENTS,
  };
};

export const PatientsSuccessful = (dados) => {
  return {
    type: PATIENTS_SUCCESSFUL,
    payload: dados,
  };
};

export const Appointments = () => {
  return {
    type: APPOINTMENTS,
  };
};

export const AppointmentsSuccessful = (dados) => {
  return {
    type: APPOINTMENTS_SUCCESSFUL,
    payload: dados,
  };
};

export const DayAppointments = () => {
  return {
    type: DAY_APPOINTMENTS,
  };
};

export const DayAppointmentsSuccessful = (dados) => {
  return {
    type: DAY_APPOINTMENTS_SUCCESSFUL,
    payload: dados,
  };
};

export const Gender = () => {
  return {
    type: GENDER,
  };
};

export const GenderSuccessful = (dados) => {
  return {
    type: GENDER_SUCCESSFUL,
    payload: dados,
  };
};

export const MonthCare = () => {
  return {
    type: MONTHCARE,
  };
};

export const MonthCareSuccessful = (dados) => {
  return {
    type: MONTHCARE_SUCCESSFUL,
    payload: dados,
  };
};

export const Insurance = () => {
  return {
    type: INSURANCE,
  };
};

export const InsuranceSuccessful = (dados) => {
  return {
    type: INSURANCE_SUCCESSFUL,
    payload: dados,
  };
};

export const Age = () => {
  return {
    type: AGE,
  };
};

export const AgeSuccessful = (dados) => {
  return {
    type: AGE_SUCCESSFUL,
    payload: dados,
  };
};
