import {
  LISTA_FINANCEIRO_BANKSACCOUNTS,
  LISTA_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
  LISTA_FINANCEIRO_LISTBANKS,
  LISTA_FINANCEIRO_LISTBANKS_SUCCESSFUL,
  CADASTRAR_FINANCEIRO_BANKSACCOUNTS,
  CADASTRAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
  EDITAR_FINANCEIRO_BANKSACCOUNTS,
  EDITAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
  DELETAR_FINANCEIRO_BANKSACCOUNTS,
  DELETAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
  VIEW_FINANCEIRO_BANKSACCOUNTS,
  VIEW_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
} from './actionTypes';

export const ListaFinanceiroBankAccounts = (search, page, total) => {
  return {
    type: LISTA_FINANCEIRO_BANKSACCOUNTS,
    payload: { search, page, total },
  };
};

export const ListaFinanceiroBankAccountsSuccessful = (dados) => {
  return {
    type: LISTA_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
    payload: dados,
  };
};

export const ListaFinanceiroListBanks = (search) => {
  return {
    type: LISTA_FINANCEIRO_LISTBANKS,
    payload: { search },
  };
};

export const ListaFinanceiroListBanksSuccessful = (dados) => {
  return {
    type: LISTA_FINANCEIRO_LISTBANKS_SUCCESSFUL,
    payload: dados,
  };
};

export const CadastrarFinanceiroBankAccounts = (
  nome,
  titular,
  cpfcnpj,
  banco,
  agencia,
  conta,
  tipo
) => {
  return {
    type: CADASTRAR_FINANCEIRO_BANKSACCOUNTS,
    payload: { nome, titular, cpfcnpj, banco, agencia, conta, tipo },
  };
};

export const CadastrarFinanceiroBankAccountsSuccessful = (response) => {
  return {
    type: CADASTRAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
    payload: response,
  };
};

export const EditarFinanceiroBankAccounts = (
  id,
  nome,
  titular,
  cpfcnpj,
  banco,
  agencia,
  conta,
  tipo
) => {
  return {
    type: EDITAR_FINANCEIRO_BANKSACCOUNTS,
    payload: { id, nome, titular, cpfcnpj, banco, agencia, conta, tipo },
  };
};

export const EditarFinanceiroBankAccountsSuccessful = (response) => {
  return {
    type: EDITAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
    payload: response,
  };
};

export const DeletarFinanceiroBankAccounts = (id) => {
  return {
    type: DELETAR_FINANCEIRO_BANKSACCOUNTS,
    payload: id,
  };
};

export const DeletarFinanceiroBankAccountsSuccessful = (response) => {
  return {
    type: DELETAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
    payload: response,
  };
};

export const ViewFinanceiroBankAccounts = (id) => {
  return {
    type: VIEW_FINANCEIRO_BANKSACCOUNTS,
    payload: id,
  };
};

export const ViewFinanceiroBankAccountsSuccessful = (dados) => {
  return {
    type: VIEW_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
    payload: dados,
  };
};
