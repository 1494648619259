import {
  PATIENTS,
  PATIENTS_SUCCESSFUL,
  APPOINTMENTS,
  APPOINTMENTS_SUCCESSFUL,
  DAY_APPOINTMENTS,
  DAY_APPOINTMENTS_SUCCESSFUL,
  GENDER,
  GENDER_SUCCESSFUL,
  MONTHCARE,
  MONTHCARE_SUCCESSFUL,
  INSURANCE,
  INSURANCE_SUCCESSFUL,
  AGE,
  AGE_SUCCESSFUL,
} from './actionTypes';

const INIT_STATE = {
  error: '',
  patients: {
    data: {
      total: 0,
      month: 0,
    },
    isLoading: true,
  },
  appointments: {
    data: {
      total: 0,
      month: 0,
    },
    isLoading: true,
  },
  dayappointments: {
    data: {
      today: 0,
      month: 0,
    },
    isLoading: true,
  },
  gender: {
    data: {
      male: 0,
      female: 0,
    },
    isLoading: true,
  },
  monthcare: {
    data: [
      {
        id: 'Cancelados',
        color: '#e41a1c',
        data: [
          {
            x: 'JAN',
            y: 0,
          },
          {
            x: 'FEV',
            y: 0,
          },
          {
            x: 'MAR',
            y: 0,
          },
          {
            x: 'ABR',
            y: 0,
          },
          {
            x: 'MAI',
            y: 0,
          },
          {
            x: 'JUN',
            y: 0,
          },
          {
            x: 'JUL',
            y: 0,
          },
          {
            x: 'AGO',
            y: 0,
          },
          {
            x: 'SET',
            y: 0,
          },
          {
            x: 'OUT',
            y: 0,
          },
          {
            x: 'NOV',
            y: 0,
          },
          {
            x: 'DEZ',
            y: 0,
          },
        ],
      },
      {
        id: 'Aguardando',
        color: '#355f91',
        data: [
          {
            x: 'JAN',
            y: 0,
          },
          {
            x: 'FEV',
            y: 0,
          },
          {
            x: 'MAR',
            y: 0,
          },
          {
            x: 'ABR',
            y: 0,
          },
          {
            x: 'MAI',
            y: 0,
          },
          {
            x: 'JUN',
            y: 0,
          },
          {
            x: 'JUL',
            y: 0,
          },
          {
            x: 'AGO',
            y: 0,
          },
          {
            x: 'SET',
            y: 0,
          },
          {
            x: 'OUT',
            y: 0,
          },
          {
            x: 'NOV',
            y: 0,
          },
          {
            x: 'DEZ',
            y: 0,
          },
        ],
      },
      {
        id: 'Atendidos',
        color: '#61bb5b',
        data: [
          {
            x: 'JAN',
            y: 0,
          },
          {
            x: 'FEV',
            y: 0,
          },
          {
            x: 'MAR',
            y: 0,
          },
          {
            x: 'ABR',
            y: 0,
          },
          {
            x: 'MAI',
            y: 0,
          },
          {
            x: 'JUN',
            y: 0,
          },
          {
            x: 'JUL',
            y: 0,
          },
          {
            x: 'AGO',
            y: 0,
          },
          {
            x: 'SET',
            y: 0,
          },
          {
            x: 'OUT',
            y: 0,
          },
          {
            x: 'NOV',
            y: 0,
          },
          {
            x: 'DEZ',
            y: 0,
          },
        ],
      },
    ],
    isLoading: true,
  },
  insurance: {
    data: {
      labels: ['Particular'],
      datasets: [
        {
          label: '',
          borderColor: ['rgba(255, 99, 132)'],
          backgroundColor: ['rgba(255, 99, 132, 0.2)'],
          borderWidth: 2,
          data: [15],
        },
      ],
    },
    isLoading: true,
  },
  age: {
    data: [
      { faixa: 'Desconhecido', total: 0 },
      { faixa: '0-17', total: 0 },
      { faixa: '18-24', total: 0 },
      { faixa: '25-29', total: 0 },
      { faixa: '30-34', total: 0 },
      { faixa: '35-39', total: 0 },
      { faixa: '40-44', total: 0 },
      { faixa: '45-50', total: 0 },
      { faixa: '50+', total: 0 },
    ],
    isLoading: true,
  },
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PATIENTS:
      return {
        ...state,
        patients: {
          data: {
            total: 0,
            month: 0,
          },
          isLoading: true,
        },
      };
    case PATIENTS_SUCCESSFUL:
      return {
        ...state,
        patients: {
          data: {
            total: action.payload.total,
            month: action.payload.month,
          },
          isLoading: !action.payload.status,
        },
      };
    case APPOINTMENTS:
      return {
        ...state,
        appointments: {
          data: {
            total: 0,
            month: 0,
          },
          isLoading: true,
        },
      };
    case APPOINTMENTS_SUCCESSFUL:
      return {
        ...state,
        appointments: {
          data: {
            total: action.payload.total,
            month: action.payload.month,
          },
          isLoading: !action.payload.status,
        },
      };
    case DAY_APPOINTMENTS:
      return {
        ...state,
        dayappointments: {
          data: {
            today: 0,
            month: 0,
          },
          isLoading: true,
        },
      };
    case DAY_APPOINTMENTS_SUCCESSFUL:
      return {
        ...state,
        dayappointments: {
          data: {
            today: action.payload.today,
            month: action.payload.month,
          },
          isLoading: !action.payload.status,
        },
      };
    case GENDER:
      return {
        ...state,
        gender: {
          data: {
            male: 0,
            female: 0,
          },
          isLoading: true,
        },
      };
    case GENDER_SUCCESSFUL:
      return {
        ...state,
        gender: {
          data: {
            male: action.payload.male,
            female: action.payload.female,
          },
          isLoading: !action.payload.status,
        },
      };
    case MONTHCARE:
      return {
        ...state,
        monthcare: {
          data: state.monthcare.data,
          isLoading: true,
        },
      };
    case MONTHCARE_SUCCESSFUL:
      return {
        ...state,
        monthcare: {
          data: state.monthcare.data,
          isLoading: !action.payload.status,
        },
      };
    case INSURANCE:
      return {
        ...state,
        insurance: {
          data: {
            labels: ['Particular'],
            datasets: [
              {
                label: '',
                borderColor: ['rgba(255, 99, 132)'],
                backgroundColor: ['rgba(255, 99, 132, 0.2)'],
                borderWidth: 2,
                data: [15],
              },
            ],
          },
          isLoading: true,
        },
      };
    case INSURANCE_SUCCESSFUL:
      return {
        ...state,
        insurance: {
          data: {
            labels: ['Particular'],
            datasets: [
              {
                label: '',
                borderColor: ['rgba(255, 99, 132)'],
                backgroundColor: ['rgba(255, 99, 132, 0.2)'],
                borderWidth: 2,
                data: [15],
              },
            ],
          },
          isLoading: !action.payload.status,
        },
      };
    case AGE:
      return {
        ...state,
        age: {
          data: [
            { faixa: 'Desconhecido', total: 0 },
            { faixa: '0-17', total: 0 },
            { faixa: '18-24', total: 0 },
            { faixa: '25-29', total: 0 },
            { faixa: '30-34', total: 0 },
            { faixa: '35-39', total: 0 },
            { faixa: '40-44', total: 0 },
            { faixa: '45-50', total: 0 },
            { faixa: '50+', total: 0 },
          ],
          isLoading: true,
        },
      };
    case AGE_SUCCESSFUL:
      return {
        ...state,
        age: {
          data: [
            {
              faixa: 'Desconhecido',
              total: action.payload.result.desconhecido,
            },
            { faixa: '0-17', total: action.payload.result.totalmenores },
            { faixa: '18-24', total: action.payload.result.total1824 },
            { faixa: '25-29', total: action.payload.result.total2529 },
            { faixa: '30-34', total: action.payload.result.total3034 },
            { faixa: '35-39', total: action.payload.result.total3539 },
            { faixa: '40-44', total: action.payload.result.total4044 },
            { faixa: '45-50', total: action.payload.result.total4549 },
            { faixa: '50+', total: action.payload.result.total50 },
          ],
          isLoading: !action.payload.status,
        },
      };
    default:
      return { ...state };
  }
};

export default Dashboard;
