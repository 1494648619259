import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
  GRAPHIC_FINANCEIRO_EXTRACT,
  LIST_FINANCEIRO_EXTRACT,
  VIEW_FINANCEIRO_EXTRACT,
  VIEW_FINANCEIRO_EXTRACT_ACCOUNTS,
} from './actionTypes';
import {
  ListaFinanceiroExtractSuccessful,
  ViewFinanceiroExtractSuccessful,
  ViewFinanceiroExtractAccountsSuccessful,
  GraphicFinanceiroExtractSuccessful,
} from './actions';
import { apiError } from '../../actions';

// Include Both Helper File with needed methods
import { getJWT } from '../../../helpers/JWTHelper';

function* ListFinanceiroExtract({
  payload: {
    search,
    status,
    receita,
    despesa,
    datainicial,
    datafinal,
    conta,
    page,
  },
}) {
  try {
    const response = yield call(
      getJWT,
      `financial/extract/list?search=${search}&status=${status}&catreceita=${receita}&catdespesa=${despesa}&datainicial=${datainicial}&datafinal=${datafinal}&conta=${conta}&page=${page}`
    );
    yield put(ListaFinanceiroExtractSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchListFinanceiroExtract() {
  yield takeEvery(LIST_FINANCEIRO_EXTRACT, ListFinanceiroExtract);
}

function* ViewFinanceiroExtract({
  payload: { search, status, receita, despesa, datainicial, datafinal, conta },
}) {
  try {
    const response = yield call(
      getJWT,
      `financial/extract/flow?search=${search}&status=${status}&catreceita=${receita}&catdespesa=${despesa}&datainicial=${datainicial}&datafinal=${datafinal}&conta=${conta}`
    );
    yield put(ViewFinanceiroExtractSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchViewFinanceiroExtract() {
  yield takeEvery(VIEW_FINANCEIRO_EXTRACT, ViewFinanceiroExtract);
}

function* ViewFinanceiroExtractAccounts({
  payload: { search, status, receita, despesa, datainicial, datafinal, conta },
}) {
  try {
    const response = yield call(
      getJWT,
      `financial/extract/accounts?search=${search}&status=${status}&catreceita=${receita}&catdespesa=${despesa}&datainicial=${datainicial}&datafinal=${datafinal}&conta=${conta}`
    );
    yield put(ViewFinanceiroExtractAccountsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchViewFinanceiroExtractAccounts() {
  yield takeEvery(
    VIEW_FINANCEIRO_EXTRACT_ACCOUNTS,
    ViewFinanceiroExtractAccounts
  );
}

function* GraphicFinanceiroExtract({
  payload: { search, status, receita, despesa, datainicial, datafinal, conta },
}) {
  try {
    const response = yield call(
      getJWT,
      `financial/extract/graphic?search=${search}&status=${status}&catreceita=${receita}&catdespesa=${despesa}&datainicial=${datainicial}&datafinal=${datafinal}&conta=${conta}`
    );
    yield put(GraphicFinanceiroExtractSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchGraphicFinanceiroExtract() {
  yield takeEvery(GRAPHIC_FINANCEIRO_EXTRACT, GraphicFinanceiroExtract);
}

function* FinanceExtractSagas() {
  yield all([
    fork(watchListFinanceiroExtract),
    fork(watchViewFinanceiroExtract),
    fork(watchViewFinanceiroExtractAccounts),
    fork(watchGraphicFinanceiroExtract),
  ]);
}

export default FinanceExtractSagas;
