export const LISTA_FINANCEIRO_COSTCENTER = 'LISTA_FINANCEIRO_COSTCENTER';
export const LISTA_FINANCEIRO_COSTCENTER_SUCCESSFUL =
  'LISTA_FINANCEIRO_COSTCENTER_SUCCESSFUL';
export const CADASTRAR_FINANCEIRO_COSTCENTER =
  'CADASTRAR_FINANCEIRO_COSTCENTER';
export const CADASTRAR_FINANCEIRO_COSTCENTER_SUCCESSFUL =
  'CADASTRAR_FINANCEIRO_COSTCENTER_SUCCESSFUL';
export const EDITAR_FINANCEIRO_COSTCENTER = 'EDITAR_FINANCEIRO_COSTCENTER';
export const EDITAR_FINANCEIRO_COSTCENTER_SUCCESSFUL =
  'EDITAR_FINANCEIRO_COSTCENTER_SUCCESSFUL';
export const DELETAR_FINANCEIRO_COSTCENTER = 'DELETAR_FINANCEIRO_COSTCENTER';
export const DELETAR_FINANCEIRO_COSTCENTER_SUCCESSFUL =
  'DELETAR_FINANCEIRO_COSTCENTER_SUCCESSFUL';
export const VIEW_FINANCEIRO_COSTCENTER = 'VIEW_FINANCEIRO_COSTCENTER';
export const VIEW_FINANCEIRO_COSTCENTER_SUCCESSFUL =
  'VIEW_FINANCEIRO_COSTCENTER_SUCCESSFUL';
