import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { PLANS } from './actionTypes';
import { PlansListSuccessful } from './actions';
import { apiError } from '../../actions';

// Include Both Helper File with needed methods
import { getJWT } from '../../../helpers/JWTHelper';

function* GetPlans() {
  try {
    const response = yield call(getJWT, `/configs_plans/plans`);
    yield put(PlansListSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchGetPlans() {
  yield takeEvery(PLANS, GetPlans);
}

function* ConfigPlansSagas() {
  yield all([fork(watchGetPlans)]);
}

export default ConfigPlansSagas;
