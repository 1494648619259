import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  DAY_APPOINTMENTS,
  APPOINTMENTS,
  PATIENTS,
  GENDER,
  MONTHCARE,
  INSURANCE,
  AGE,
} from './actionTypes';
import {
  DayAppointmentsSuccessful,
  AppointmentsSuccessful,
  PatientsSuccessful,
  GenderSuccessful,
  MonthCareSuccessful,
  InsuranceSuccessful,
  AgeSuccessful,
} from './actions';
import { apiError } from '../actions';

// Include Both Helper File with needed methods
import { getJWT } from '../../helpers/JWTHelper';

function* GetPatients() {
  try {
    const response = yield call(getJWT, `/dashboard/patients`);
    yield put(PatientsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchPatients() {
  yield takeEvery(PATIENTS, GetPatients);
}

function* GetAppointments() {
  try {
    const response = yield call(getJWT, `/dashboard/appointments`);
    yield put(AppointmentsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watcAppointments() {
  yield takeEvery(APPOINTMENTS, GetAppointments);
}

function* GetDayAppointments() {
  try {
    const response = yield call(getJWT, `/dashboard/day_appointments`);
    yield put(DayAppointmentsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchDayAppointments() {
  yield takeEvery(DAY_APPOINTMENTS, GetDayAppointments);
}

function* GetGender() {
  try {
    const response = yield call(getJWT, `/dashboard/gender`);
    yield put(GenderSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchGender() {
  yield takeEvery(GENDER, GetGender);
}

function* GetMonthCare() {
  try {
    const response = yield call(getJWT, `/dashboard/month_care`);
    yield put(MonthCareSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchMonthCare() {
  yield takeEvery(MONTHCARE, GetMonthCare);
}

function* GetInsurance() {
  try {
    const response = yield call(getJWT, `/dashboard/insurance`);
    const teste = response.data.result.filter((c) => c.nome);
    console.log(teste);
    yield put(InsuranceSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchInsurance() {
  yield takeEvery(INSURANCE, GetInsurance);
}

function* GetAge() {
  try {
    const response = yield call(getJWT, `/dashboard/age`);
    yield put(AgeSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchAge() {
  yield takeEvery(AGE, GetAge);
}

function* DashboardSagas() {
  yield all([
    fork(watchPatients),
    fork(watcAppointments),
    fork(watchDayAppointments),
    fork(watchGender),
    fork(watchMonthCare),
    fork(watchInsurance),
    fork(watchAge),
  ]);
}

export default DashboardSagas;
