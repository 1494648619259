export const VIEW_FINANCEIRO_EXTRACT = 'VIEW_FINANCEIRO_EXTRACT';
export const VIEW_FINANCEIRO_EXTRACT_SUCCESSFUL =
  'VIEW_FINANCEIRO_EXTRACT_SUCCESSFUL';
export const VIEW_FINANCEIRO_EXTRACT_ACCOUNTS =
  'VIEW_FINANCEIRO_EXTRACT_ACCOUNTS';
export const VIEW_FINANCEIRO_EXTRACT_ACCOUNTS_SUCCESSFUL =
  'VIEW_FINANCEIRO_EXTRACT_ACCOUNTS_SUCCESSFUL';
export const LIST_FINANCEIRO_EXTRACT = 'LIST_FINANCEIRO_EXTRACT';
export const LIST_FINANCEIRO_EXTRACT_SUCCESSFUL =
  'LIST_FINANCEIRO_EXTRACT_SUCCESSFUL';
export const GRAPHIC_FINANCEIRO_EXTRACT = 'GRAPHIC_FINANCEIRO_EXTRACT';
export const GRAPHIC_FINANCEIRO_EXTRACT_SUCCESSFUL =
  'GRAPHIC_FINANCEIRO_EXTRACT_SUCCESSFUL';
