import {
  LISTA_CONTAS_RECEBER,
  LISTA_CONTAS_RECEBER_SUCCESSFUL,
  CATEGORIAS_CONTA_RECEBER,
  CATEGORIAS_CONTA_RECEBER_SUCCESSFUL,
  CAIXAS_CONTA_RECEBER,
  CAIXAS_CONTA_RECEBER_SUCCESSFUL,
  CENTROCUSTO_CONTA_RECEBER,
  CENTROCUSTO_CONTA_RECEBER_SUCCESSFUL,
  PACIENTES_CONTA_RECEBER,
  PACIENTES_CONTA_RECEBER_SUCCESSFUL,
  CADASTRAR_CONTA_RECEBER,
  CADASTRAR_CONTA_RECEBER_SUCCESSFUL,
  EDITAR_CONTA_RECEBER,
  EDITAR_CONTA_RECEBER_SUCCESSFUL,
  DELETAR_CONTA_RECEBER,
  DELETAR_CONTA_RECEBER_SUCCESSFUL,
  VIEW_CONTA_RECEBER,
  VIEW_CONTA_RECEBER_SUCCESSFUL,
} from './actionTypes';

export const ListaContasReceber = (
  search,
  page,
  total,
  datainicial,
  datafinal,
  status,
  categoria
) => {
  return {
    type: LISTA_CONTAS_RECEBER,
    payload: { search, page, total, datainicial, datafinal, status, categoria },
  };
};

export const ListaContasReceberSuccessful = (dados) => {
  return {
    type: LISTA_CONTAS_RECEBER_SUCCESSFUL,
    payload: dados,
  };
};

export const CategoriasContasReceber = (search) => {
  return {
    type: CATEGORIAS_CONTA_RECEBER,
    payload: search,
  };
};

export const CategoriasContasReceberSuccessful = (dados) => {
  return {
    type: CATEGORIAS_CONTA_RECEBER_SUCCESSFUL,
    payload: dados,
  };
};

export const CaixasContasReceber = (search) => {
  return {
    type: CAIXAS_CONTA_RECEBER,
    payload: search,
  };
};

export const CaixasContasReceberSuccessful = (dados) => {
  return {
    type: CAIXAS_CONTA_RECEBER_SUCCESSFUL,
    payload: dados,
  };
};

export const CentroCustoContasReceber = (search) => {
  return {
    type: CENTROCUSTO_CONTA_RECEBER,
    payload: search,
  };
};

export const CentroCustoContasReceberSuccessful = (dados) => {
  return {
    type: CENTROCUSTO_CONTA_RECEBER_SUCCESSFUL,
    payload: dados,
  };
};

export const PacientesContasReceber = (search) => {
  return {
    type: PACIENTES_CONTA_RECEBER,
    payload: search,
  };
};

export const PacientesContasReceberSuccessful = (dados) => {
  return {
    type: PACIENTES_CONTA_RECEBER_SUCCESSFUL,
    payload: dados,
  };
};

export const CadastrarContasReceber = (dados) => {
  return {
    type: CADASTRAR_CONTA_RECEBER,
    payload: dados,
  };
};

export const CadastrarContasReceberSuccessful = (response) => {
  return {
    type: CADASTRAR_CONTA_RECEBER_SUCCESSFUL,
    payload: response,
  };
};

export const EditarContasReceber = (dados) => {
  return {
    type: EDITAR_CONTA_RECEBER,
    payload: dados,
  };
};

export const EditarContasReceberSuccessful = (response) => {
  return {
    type: EDITAR_CONTA_RECEBER_SUCCESSFUL,
    payload: response,
  };
};

export const DeletarContasReceber = (id) => {
  return {
    type: DELETAR_CONTA_RECEBER,
    payload: id,
  };
};

export const DeletarContasReceberSuccessful = (response) => {
  return {
    type: DELETAR_CONTA_RECEBER_SUCCESSFUL,
    payload: response,
  };
};

export const ViewContasReceber = (id) => {
  return {
    type: VIEW_CONTA_RECEBER,
    payload: id,
  };
};

export const ViewContasReceberSuccessful = (dados) => {
  return {
    type: VIEW_CONTA_RECEBER_SUCCESSFUL,
    payload: dados,
  };
};
