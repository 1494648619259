import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
  FORGET_PASSWORD,
  VERIFY_TOKEN,
  UPDATE_USER_PASSWORD,
} from './actionTypes';
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  verifyTokenForgetPasswordSuccess,
  verifyTokenForgetPasswordError,
  UpdateUserPasswordSuccess,
  UpdateUserPasswordError,
} from '../../actions';
import { postJWT, getJWT } from '../../../helpers/JWTHelper';

function* forgetUser({ payload: email }) {
  try {
    const response = yield call(postJWT, `recovery/send`, {
      email,
    });
    if (response.data.success) {
      yield put(userForgetPasswordSuccess(response.data));
    } else {
      yield put(userForgetPasswordError(response.data.msg));
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* verifyToken({ payload: token }) {
  try {
    const response = yield call(getJWT, `recovery/verify/${token}`);
    if (response.data.error) {
      yield put(verifyTokenForgetPasswordError(response.data.msg));
    } else {
      yield put(verifyTokenForgetPasswordSuccess(response.data));
    }
  } catch (error) {
    yield put(verifyTokenForgetPasswordError(error));
  }
}

export function* watchverifyToken() {
  yield takeEvery(VERIFY_TOKEN, verifyToken);
}

function* UpdatePassword({ payload: { password, token, history } }) {
  const response = yield call(postJWT, `recovery/update`, {
    token,
    password,
  });
  if (response.data.error) {
    yield put(UpdateUserPasswordError(response.data.msg));
  } else {
    yield put(UpdateUserPasswordSuccess(response.data));
    localStorage.setItem('@waydoctor-app/token', response.data.token);
    history.push('/dashboard');
  }
}

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_USER_PASSWORD, UpdatePassword);
}

function* forgetPasswordSagas() {
  yield all([
    fork(watchUserPasswordForget),
    fork(watchverifyToken),
    fork(watchUpdatePassword),
  ]);
}

export default forgetPasswordSagas;
