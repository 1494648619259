import {
  LISTA_CONTAS_PAGAR,
  LISTA_CONTAS_PAGAR_SUCCESSFUL,
  CATEGORIAS_CONTA_PAGAR,
  CATEGORIAS_CONTA_PAGAR_SUCCESSFUL,
  CAIXAS_CONTA_PAGAR,
  CAIXAS_CONTA_PAGAR_SUCCESSFUL,
  CENTROCUSTO_CONTA_PAGAR,
  CENTROCUSTO_CONTA_PAGAR_SUCCESSFUL,
  PACIENTES_CONTA_PAGAR,
  PACIENTES_CONTA_PAGAR_SUCCESSFUL,
  CADASTRAR_CONTA_PAGAR,
  CADASTRAR_CONTA_PAGAR_SUCCESSFUL,
  EDITAR_CONTA_PAGAR,
  EDITAR_CONTA_PAGAR_SUCCESSFUL,
  DELETAR_CONTA_PAGAR,
  DELETAR_CONTA_PAGAR_SUCCESSFUL,
  VIEW_CONTA_PAGAR,
  VIEW_CONTA_PAGAR_SUCCESSFUL,
} from './actionTypes';

export const ListaContasPagar = (
  search,
  page,
  total,
  datainicial,
  datafinal,
  status,
  categoria
) => {
  return {
    type: LISTA_CONTAS_PAGAR,
    payload: { search, page, total, datainicial, datafinal, status, categoria },
  };
};

export const ListaContasPagarSuccessful = (dados) => {
  return {
    type: LISTA_CONTAS_PAGAR_SUCCESSFUL,
    payload: dados,
  };
};

export const CategoriasContasPagar = (search) => {
  return {
    type: CATEGORIAS_CONTA_PAGAR,
    payload: search,
  };
};

export const CategoriasContasPagarSuccessful = (dados) => {
  return {
    type: CATEGORIAS_CONTA_PAGAR_SUCCESSFUL,
    payload: dados,
  };
};

export const CaixasContasPagar = (search) => {
  return {
    type: CAIXAS_CONTA_PAGAR,
    payload: search,
  };
};

export const CaixasContasPagarSuccessful = (dados) => {
  return {
    type: CAIXAS_CONTA_PAGAR_SUCCESSFUL,
    payload: dados,
  };
};

export const CentroCustoContasPagar = (search) => {
  return {
    type: CENTROCUSTO_CONTA_PAGAR,
    payload: search,
  };
};

export const CentroCustoContasPagarSuccessful = (dados) => {
  return {
    type: CENTROCUSTO_CONTA_PAGAR_SUCCESSFUL,
    payload: dados,
  };
};

export const PacientesContasPagar = (search) => {
  return {
    type: PACIENTES_CONTA_PAGAR,
    payload: search,
  };
};

export const PacientesContasPagarSuccessful = (dados) => {
  return {
    type: PACIENTES_CONTA_PAGAR_SUCCESSFUL,
    payload: dados,
  };
};

export const CadastrarContasPagar = (dados) => {
  return {
    type: CADASTRAR_CONTA_PAGAR,
    payload: dados,
  };
};

export const CadastrarContasPagarSuccessful = (response) => {
  return {
    type: CADASTRAR_CONTA_PAGAR_SUCCESSFUL,
    payload: response,
  };
};

export const EditarContasPagar = (dados) => {
  return {
    type: EDITAR_CONTA_PAGAR,
    payload: dados,
  };
};

export const EditarContasPagarSuccessful = (response) => {
  return {
    type: EDITAR_CONTA_PAGAR_SUCCESSFUL,
    payload: response,
  };
};

export const DeletarContasPagar = (id) => {
  return {
    type: DELETAR_CONTA_PAGAR,
    payload: id,
  };
};

export const DeletarContasPagarSuccessful = (response) => {
  return {
    type: DELETAR_CONTA_PAGAR_SUCCESSFUL,
    payload: response,
  };
};

export const ViewContasPagar = (id) => {
  return {
    type: VIEW_CONTA_PAGAR,
    payload: id,
  };
};

export const ViewContasPagarSuccessful = (dados) => {
  return {
    type: VIEW_CONTA_PAGAR_SUCCESSFUL,
    payload: dados,
  };
};
