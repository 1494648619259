import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  VERIFY_TOKEN,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_ERROR,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
} from './actionTypes';

const initialState = {
  forgetMsg: {
    status: false,
    msg: '',
  },
  VerifyToken: {
    status: false,
    msg: '',
  },
  UserPassword: {
    status: false,
    msg: '',
  },
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      return {
        ...state,
        forgetMsg: {
          status: false,
          msg: '',
        },
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetMsg: {
          status: true,
          msg: '',
        },
      };
    case FORGET_PASSWORD_ERROR:
      return {
        ...state,
        forgetMsg: {
          status: false,
          msg: action.payload,
        },
      };
    case VERIFY_TOKEN:
      return {
        ...state,
        VerifyToken: {
          status: false,
          msg: '',
        },
      };
    case VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        VerifyToken: {
          status: false,
          msg: '',
        },
      };
    case VERIFY_TOKEN_ERROR:
      return {
        ...state,
        VerifyToken: {
          status: true,
          msg: action.payload,
        },
      };
    case UPDATE_USER_PASSWORD:
      return {
        ...state,
        UserPassword: {
          status: false,
          msg: '',
        },
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        UserPassword: {
          status: false,
          msg: '',
        },
      };
    case UPDATE_USER_PASSWORD_ERROR:
      return {
        ...state,
        UserPassword: {
          status: true,
          msg: action.payload,
        },
      };
    default:
      return { ...state };
  }
};

export default forgetPassword;
