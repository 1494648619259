export const PATIENTS = 'PATIENTS';
export const PATIENTS_SUCCESSFUL = 'PATIENTS_SUCCESSFUL';
export const APPOINTMENTS = 'APPOINTMENTS';
export const APPOINTMENTS_SUCCESSFUL = 'APPOINTMENTS_SUCCESSFUL';
export const DAY_APPOINTMENTS = 'DAY_APPOINTMENTS';
export const DAY_APPOINTMENTS_SUCCESSFUL = 'DAY_APPOINTMENTS_SUCCESSFUL';
export const GENDER = 'GENDER';
export const GENDER_SUCCESSFUL = 'GENDER_SUCCESSFUL';
export const MONTHCARE = 'MONTHCARE';
export const MONTHCARE_SUCCESSFUL = 'MONTHCARE_SUCCESSFUL';
export const AGE = 'AGE';
export const AGE_SUCCESSFUL = 'AGE_SUCCESSFUL';
export const INSURANCE = 'INSURANCE';
export const INSURANCE_SUCCESSFUL = 'INSURANCE_SUCCESSFUL';
