import {
  WPP,
  WPP_SUCCESSFUL,
  WPP_VERIFY,
  WPP_VERIFY_SUCCESSFUL,
} from './actionTypes';

const INIT_STATE = {
  error: '',
  WppVerifyInfos: {
    status: false,
    token: '',
    isLoading: true,
  },
  WppInfos: {
    message: '',
    status: '',
    qrcode: '',
    isLoading: true,
  },
};

const WppConfig = (state = INIT_STATE, action) => {
  switch (action.type) {
    case WPP_VERIFY:
      return {
        ...state,
        WppVerifyInfos: {
          status: false,
          token: '',
          isLoading: true,
        },
      };
    case WPP_VERIFY_SUCCESSFUL:
      return {
        ...state,
        WppVerifyInfos: {
          status: action.payload.status,
          token: action.payload.token,
          isLoading: false,
        },
      };
    case WPP:
      return {
        ...state,
        WppInfos: {
          message: '',
          status: '',
          qrcode: '',
          isLoading: true,
        },
      };
    case WPP_SUCCESSFUL:
      return {
        ...state,
        WppInfos: {
          message: action.payload.message,
          status: action.payload.status,
          qrcode: action.payload.qrcode,
          isLoading: false,
        },
      };
    default:
      return { ...state };
  }
};

export default WppConfig;
