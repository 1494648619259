import {
  LOGIN_USER,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  VERIFY_TOKEN_JWT,
  VERIFY_TOKEN_JWT_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  error: '',
  alert: '',
  loading: false,
  user: {
    id: '',
    nome: '',
    config: false,
    plan: 0,
    validade: '',
    categoria: '',
    cargo: '',
    email: '',
    telefone: '',
    token: '',
    nascimento: '',
    managernome: '',
    manageremail: '',
    managerwhatsapp: '',
  },
  VerifyUser: false,
};

const authUser = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        user: {
          id: '',
          nome: '',
          config: false,
          plan: 0,
          validade: '',
          categoria: '',
          cargo: '',
          email: '',
          telefone: '',
          token: '',
          nascimento: '',
          managernome: '',
          manageremail: '',
          managerwhatsapp: '',
        },
        loading: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        alert: action.payload.error,
        loading: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: {
          id: '',
          nome: action.payload.nome,
          config: false,
          plan: 0,
          validade: '',
          categoria: '',
          cargo: '',
          email: '',
          telefone: '',
          token: '',
          nascimento: '',
          managernome: '',
          manageremail: '',
          managerwhatsapp: '',
        },
        loading: false,
      };
    case LOGOUT_USER:
      return { ...state };
    case VERIFY_TOKEN_JWT:
      return {
        ...state,
        user: {
          id: '',
          nome: '',
          config: false,
          plan: 0,
          validade: '',
          categoria: '',
          cargo: '',
          email: '',
          telefone: '',
          token: '',
          nascimento: '',
          managernome: '',
          manageremail: '',
          managerwhatsapp: '',
        },
        loading: true,
      };
    case VERIFY_TOKEN_JWT_SUCCESS:
      return {
        ...state,
        user: {
          id: action.payload.id_user,
          nome: action.payload.nome,
          config: action.payload.config,
          plan: action.payload.plano,
          validade: action.payload.validade,
          categoria: action.payload.categoria,
          cargo: action.payload.cargo,
          email: action.payload.email,
          telefone: action.payload.telefone,
          token: action.payload.token,
          nascimento: action.payload.nascimento,
          managernome: action.payload.managernome,
          manageremail: action.payload.manageremail,
          managerwhatsapp: action.payload.managerwhatsapp,
        },
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default authUser;
