import {
  CHANGE_LOCALE,
  CATEGORY_PROFISSIONAL,
  CATEGORY_PROFISSIONAL_SUCCESSFUL,
  EDITAR_USER,
  EDITAR_USER_SUCCESSFUL,
  EDITAR_USER_FAILED,
  EDITAR_USER_PASS,
  EDITAR_USER_PASS_FAILED,
  EDITAR_USER_PASS_SUCCESSFUL,
} from './actionTypes';
import { getCurrentLanguage } from '../../helpers/Utils';

const INIT_STATE = {
  locale: getCurrentLanguage(),
  CategoriasProfissional: {
    data: {
      result: [],
    },
    isLoading: true,
  },
  HasEmail: false,
  LimitePlano: false,
  AtualPass: false,
  isSave: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case CATEGORY_PROFISSIONAL:
      return {
        ...state,
        CategoriasProfissional: {
          data: {
            result: [],
          },
          isLoading: true,
        },
      };
    case CATEGORY_PROFISSIONAL_SUCCESSFUL:
      return {
        ...state,
        CategoriasProfissional: {
          data: { result: action.payload.result },
          isLoading: false,
        },
      };
    case EDITAR_USER:
      return {
        ...state,
        HasEmail: false,
        LimitePlano: false,
        isSave: false,
      };
    case EDITAR_USER_SUCCESSFUL:
      return {
        ...state,
        HasEmail: false,
        LimitePlano: false,
        isSave: true,
      };
    case EDITAR_USER_FAILED:
      return {
        ...state,
        HasEmail: action.payload.email,
        LimitePlano: action.payload.plano,
        isSave: false,
      };
    case EDITAR_USER_PASS:
      return {
        ...state,
        AtualPass: false,
        isSave: false,
      };
    case EDITAR_USER_PASS_SUCCESSFUL:
      return {
        ...state,
        AtualPass: false,
        isSave: true,
      };
    case EDITAR_USER_PASS_FAILED:
      return {
        ...state,
        AtualPass: true,
        isSave: false,
      };
    default:
      return { ...state };
  }
};
