import {
  LISTA_FINANCEIRO_CATEGORY,
  LISTA_FINANCEIRO_CATEGORY_SUCCESSFUL,
  CADASTRAR_FINANCEIRO_CATEGORY,
  CADASTRAR_FINANCEIRO_CATEGORY_SUCCESSFUL,
  EDITAR_FINANCEIRO_CATEGORY,
  EDITAR_FINANCEIRO_CATEGORY_SUCCESSFUL,
  VIEW_FINANCEIRO_CATEGORY,
  VIEW_FINANCEIRO_CATEGORY_SUCCESSFUL,
} from './actionTypes';

const INIT_STATE = {
  error: '',
  categorias: {
    data: {
      result: [],
      pagination: {
        start: 0,
        end: 0,
        total: 0,
      },
    },
    isLoading: true,
  },
  category: {
    data: {
      result: [],
    },
    isLoading: true,
  },
  isSave: false,
};

const FinanceCategory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTA_FINANCEIRO_CATEGORY:
      return {
        ...state,
        categorias: {
          data: {
            result: [],
            pagination: {
              start: 0,
              end: 0,
              total: 0,
            },
          },
          isLoading: true,
        },
      };
    case LISTA_FINANCEIRO_CATEGORY_SUCCESSFUL:
      return {
        ...state,
        categorias: {
          data: {
            result: action.payload.result,
            pagination: action.payload.pagination,
          },
          isLoading: false,
        },
      };
    case CADASTRAR_FINANCEIRO_CATEGORY:
      return {
        ...state,
        isSave: false,
      };
    case CADASTRAR_FINANCEIRO_CATEGORY_SUCCESSFUL:
      return {
        ...state,
        isSave: true,
      };
    case EDITAR_FINANCEIRO_CATEGORY:
      return {
        ...state,
        isSave: false,
      };
    case EDITAR_FINANCEIRO_CATEGORY_SUCCESSFUL:
      return {
        ...state,
        isSave: true,
      };
    case VIEW_FINANCEIRO_CATEGORY:
      return {
        ...state,
        category: {
          data: {
            result: [],
          },
          isLoading: true,
        },
      };
    case VIEW_FINANCEIRO_CATEGORY_SUCCESSFUL:
      return {
        ...state,
        category: {
          data: { result: action.payload },
          isLoading: false,
        },
      };
    default:
      return { ...state };
  }
};

export default FinanceCategory;
