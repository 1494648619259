import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  LISTA_FINANCEIRO_COSTCENTER,
  CADASTRAR_FINANCEIRO_COSTCENTER,
  EDITAR_FINANCEIRO_COSTCENTER,
  VIEW_FINANCEIRO_COSTCENTER,
  DELETAR_FINANCEIRO_COSTCENTER,
} from './actionTypes';
import {
  ListaFinanceiroCostCenterSuccessful,
  CadastrarFinanceiroCostCenterSuccessful,
  EditarFinanceiroCostCenterSuccessful,
  DeletarFinanceiroCostCenterSuccessful,
  ViewFinanceiroCostCenterSuccessful,
} from './actions';
import { apiError } from '../../actions';

// Include Both Helper File with needed methods
import { postJWT, getJWT } from '../../../helpers/JWTHelper';

function* GetFinanceiroCostCenter({ payload: { search, page, total } }) {
  try {
    const response = yield call(
      getJWT,
      `financial/costcenter/list?search=${search}&page=${page}&total=${total}`
    );
    yield put(ListaFinanceiroCostCenterSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchListaFinanceiroCostCenter() {
  yield takeEvery(LISTA_FINANCEIRO_COSTCENTER, GetFinanceiroCostCenter);
}

function* ViewFinanceiroCostCenter({ payload: id }) {
  try {
    const response = yield call(getJWT, `financial/costcenter/view/${id}`);
    yield put(ViewFinanceiroCostCenterSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchViewFinanceiroCostCenter() {
  yield takeEvery(VIEW_FINANCEIRO_COSTCENTER, ViewFinanceiroCostCenter);
}

function* CadastrarFinanceiroCostCenter({ payload: { nome, tipo } }) {
  try {
    const response = yield call(postJWT, `financial/costcenter/create`, {
      nome,
      tipo,
    });
    yield put(CadastrarFinanceiroCostCenterSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCadastrar() {
  yield takeEvery(
    CADASTRAR_FINANCEIRO_COSTCENTER,
    CadastrarFinanceiroCostCenter
  );
}

function* EditarFinanceiroCostCenter({ payload: dados }) {
  try {
    const response = yield call(postJWT, `financial/costcenter/update`, {
      id: dados.id,
      nome: dados.nome,
      tipo: dados.tipo,
    });
    yield put(EditarFinanceiroCostCenterSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchEditar() {
  yield takeEvery(EDITAR_FINANCEIRO_COSTCENTER, EditarFinanceiroCostCenter);
}

function* DeletarFinanceiroCostCenter({ payload: id }) {
  try {
    const response = yield call(postJWT, `financial/costcenter/delete`, {
      id,
    });
    yield put(DeletarFinanceiroCostCenterSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchDeletar() {
  yield takeEvery(DELETAR_FINANCEIRO_COSTCENTER, DeletarFinanceiroCostCenter);
}

function* FinanceCostCenterSagas() {
  yield all([
    fork(watchListaFinanceiroCostCenter),
    fork(watchViewFinanceiroCostCenter),
    fork(watchCadastrar),
    fork(watchEditar),
    fork(watchDeletar),
  ]);
}

export default FinanceCostCenterSagas;
