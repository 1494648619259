import { PLANS, PLANS_SUCCESSFUL } from './actionTypes';

const INIT_STATE = {
  error: '',
  plansInfos: {
    planos: [],
    isLoading: true,
  },
};

const PlansConfig = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PLANS:
      return {
        ...state,
        plansInfos: {
          planos: [],
          isLoading: true,
        },
      };
    case PLANS_SUCCESSFUL:
      return {
        ...state,
        plansInfos: {
          planos: action.payload.planos,
          isLoading: false,
        },
      };
    default:
      return { ...state };
  }
};

export default PlansConfig;
