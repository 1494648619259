export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CATEGORY_PROFISSIONAL = 'CATEGORY_PROFISSIONAL';
export const CATEGORY_PROFISSIONAL_SUCCESSFUL =
  'CATEGORY_PROFISSIONAL_SUCCESSFUL';
export const EDITAR_USER = 'EDITAR_USER';
export const EDITAR_USER_SUCCESSFUL = 'EDITAR_USER_SUCCESSFUL';
export const EDITAR_USER_FAILED = 'EDITAR_USER_FAILED';
export const EDITAR_USER_PASS = 'EDITAR_USER_PASS';
export const EDITAR_USER_PASS_SUCCESSFUL = 'EDITAR_USER_PASS_SUCCESSFUL';
export const EDITAR_USER_PASS_FAILED = 'EDITAR_USER_PASS_FAILED';
