import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { WPP_VERIFY, WPP } from './actionTypes';
import { WppVerifySuccessful, WppActiveSuccessful } from './actions';
import { apiError } from '../../../actions';

// Include Both Helper File with needed methods
import { getJWT } from '../../../../helpers/JWTHelper';

function* GetVerifyWpp() {
  try {
    const response = yield call(getJWT, `/wpp/verify`);
    yield put(WppVerifySuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchGetVerifyWpp() {
  yield takeEvery(WPP_VERIFY, GetVerifyWpp);
}

function* GetWpp() {
  try {
    const response = yield call(getJWT, `/wpp/connect`);
    console.log(response.data);
    yield put(WppActiveSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchGetWpp() {
  yield takeEvery(WPP, GetWpp);
}

function* ConfigWppSagas() {
  yield all([fork(watchGetVerifyWpp), fork(watchGetWpp)]);
}

export default ConfigWppSagas;
