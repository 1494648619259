import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  VERIFY_TOKEN,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_ERROR,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
} from './actionTypes';

export const userForgetPassword = (email) => {
  return {
    type: FORGET_PASSWORD,
    payload: email,
  };
};

export const userForgetPasswordSuccess = (message) => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userForgetPasswordError = (message) => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  };
};

export const verifyTokenForgetPassword = (token) => {
  return {
    type: VERIFY_TOKEN,
    payload: token,
  };
};

export const verifyTokenForgetPasswordSuccess = (data) => {
  return {
    type: VERIFY_TOKEN_SUCCESS,
    payload: data,
  };
};

export const verifyTokenForgetPasswordError = (message) => {
  return {
    type: VERIFY_TOKEN_ERROR,
    payload: message,
  };
};

export const UpdateUserPassword = (password, token, history) => {
  return {
    type: UPDATE_USER_PASSWORD,
    payload: { password, token, history },
  };
};

export const UpdateUserPasswordSuccess = (message) => {
  return {
    type: UPDATE_USER_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const UpdateUserPasswordError = (message) => {
  return {
    type: UPDATE_USER_PASSWORD_ERROR,
    payload: message,
  };
};
