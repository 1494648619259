import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  CATEGORY_PROFISSIONAL,
  EDITAR_USER,
  EDITAR_USER_PASS,
} from './actionTypes';
import {
  CategoryProfissionalSuccessful,
  EditarUserSuccessful,
  EditarUserFailed,
  EditarUserPassSuccessful,
  EditarUserPassFailed,
} from './actions';
import { apiError } from '../actions';

// Include Both Helper File with needed methods
import { getJWT, postJWT } from '../../helpers/JWTHelper';

function* GetProfissionalCategory() {
  try {
    const response = yield call(getJWT, `settings/profissional_category`);
    yield put(CategoryProfissionalSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchProfissionalCategory() {
  yield takeEvery(CATEGORY_PROFISSIONAL, GetProfissionalCategory);
}

function* EditarUser({ payload: dados }) {
  try {
    const response = yield call(postJWT, `users/update`, {
      id: dados.id,
      cargo: dados.cargo,
      nome: dados.nome,
      email: dados.email,
      telefone: dados.telefone,
      nascimento: dados.nascimento,
    });
    if (response.data.status) {
      yield put(EditarUserSuccessful(response.data));
    } else {
      yield put(EditarUserFailed(response.data));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchEditarUser() {
  yield takeEvery(EDITAR_USER, EditarUser);
}

function* EditarUserPass({ payload: dados }) {
  try {
    const response = yield call(postJWT, `users/updatepass`, {
      id: dados.id,
      senhaatual: dados.senhaatual,
      senha: dados.senha,
    });
    if (response.data.status) {
      yield put(EditarUserPassSuccessful(response.data));
    } else {
      yield put(EditarUserPassFailed(response.data));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchEditarUserPass() {
  yield takeEvery(EDITAR_USER_PASS, EditarUserPass);
}

function* SettingsSagas() {
  yield all([
    fork(watchProfissionalCategory),
    fork(watchEditarUser),
    fork(watchEditarUserPass),
  ]);
}

export default SettingsSagas;
