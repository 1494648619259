export const LISTA_FINANCEIRO_MOVES = 'LISTA_FINANCEIRO_MOVES';
export const LISTA_FINANCEIRO_MOVES_SUCCESSFUL =
  'LISTA_FINANCEIRO_MOVES_SUCCESSFUL';
export const CADASTRAR_FINANCEIRO_MOVES = 'CADASTRAR_FINANCEIRO_MOVES';
export const CADASTRAR_FINANCEIRO_MOVES_SUCCESSFUL =
  'CADASTRAR_FINANCEIRO_MOVES_SUCCESSFUL';
export const EDITAR_FINANCEIRO_MOVES = 'EDITAR_FINANCEIRO_MOVES';
export const EDITAR_FINANCEIRO_MOVES_SUCCESSFUL =
  'EDITAR_FINANCEIRO_MOVES_SUCCESSFUL';
export const DELETAR_FINANCEIRO_MOVES = 'DELETAR_FINANCEIRO_MOVES';
export const DELETAR_FINANCEIRO_MOVES_SUCCESSFUL =
  'DELETAR_FINANCEIRO_MOVES_SUCCESSFUL';
export const VIEW_FINANCEIRO_MOVES = 'VIEW_FINANCEIRO_MOVES';
export const VIEW_FINANCEIRO_MOVES_SUCCESSFUL =
  'VIEW_FINANCEIRO_MOVES_SUCCESSFUL';
export const ACCOUNTS_FINANCEIRO_MOVES = 'ACCOUNTS_FINANCEIRO_MOVES';
export const ACCOUNTS_FINANCEIRO_MOVES_SUCCESSFUL =
  'ACCOUNTS_FINANCEIRO_MOVES_SUCCESSFUL';
