export const LISTA_CONTAS_PAGAR = 'LISTA_CONTAS_PAGAR';
export const LISTA_CONTAS_PAGAR_SUCCESSFUL = 'LISTA_CONTAS_PAGAR_SUCCESSFUL';
export const CATEGORIAS_CONTA_PAGAR = 'CATEGORIAS_CONTA_PAGAR';
export const CATEGORIAS_CONTA_PAGAR_SUCCESSFUL =
  'CATEGORIAS_CONTA_PAGAR_SUCCESSFUL';
export const CAIXAS_CONTA_PAGAR = 'CAIXAS_CONTA_PAGAR';
export const CAIXAS_CONTA_PAGAR_SUCCESSFUL = 'CAIXAS_CONTA_PAGAR_SUCCESSFUL';
export const CENTROCUSTO_CONTA_PAGAR = 'CENTROCUSTO_CONTA_PAGAR';
export const CENTROCUSTO_CONTA_PAGAR_SUCCESSFUL =
  'CENTROCUSTO_CONTA_PAGAR_SUCCESSFUL';
export const PACIENTES_CONTA_PAGAR = 'PACIENTES_CONTA_PAGAR';
export const PACIENTES_CONTA_PAGAR_SUCCESSFUL =
  'PACIENTES_CONTA_PAGAR_SUCCESSFUL';
export const CADASTRAR_CONTA_PAGAR = 'CADASTRAR_CONTA_PAGAR';
export const CADASTRAR_CONTA_PAGAR_SUCCESSFUL =
  'CADASTRAR_CONTA_PAGAR_SUCCESSFUL';
export const EDITAR_CONTA_PAGAR = 'EDITAR_CONTA_PAGAR';
export const EDITAR_CONTA_PAGAR_SUCCESSFUL = 'EDITAR_CONTA_PAGAR_SUCCESSFUL';
export const DELETAR_CONTA_PAGAR = 'DELETAR_CONTA_PAGAR';
export const DELETAR_CONTA_PAGAR_SUCCESSFUL = 'DELETAR_CONTA_PAGAR_SUCCESSFUL';
export const VIEW_CONTA_PAGAR = 'VIEW_CONTA_PAGAR';
export const VIEW_CONTA_PAGAR_SUCCESSFUL = 'VIEW_CONTA_PAGAR_SUCCESSFUL';
