import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/login/reducer';
import forgetPassword from './auth/forgot-password/reducer';
import Dashboard from './dashboard/reducer';
import FinanceExtract from './financial/extract/reducer';
import FinanceCashFlow from './financial/cashflow/reducer';
import FinanceMoves from './financial/moves/reducer';
import Revenue from './financial/revenue/reducer';
import Expenditure from './financial/expenditure/reducer';
import FinanceCategory from './financial/category/reducer';
import FinanceCostCenter from './financial/costcenter/reducer';
import FinanceBankAccounts from './financial/bankaccounts/reducer';
import PlansConfig from './configs/plan/reducer';
import WppConfig from './configs/account/integration/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  forgetPassword,
  Dashboard,
  FinanceExtract,
  FinanceCashFlow,
  FinanceMoves,
  Revenue,
  Expenditure,
  FinanceCostCenter,
  FinanceCategory,
  FinanceBankAccounts,
  PlansConfig,
  WppConfig,
});

export default reducers;
