import {
  LISTA_FINANCEIRO_MOVES,
  LISTA_FINANCEIRO_MOVES_SUCCESSFUL,
  CADASTRAR_FINANCEIRO_MOVES,
  CADASTRAR_FINANCEIRO_MOVES_SUCCESSFUL,
  EDITAR_FINANCEIRO_MOVES,
  EDITAR_FINANCEIRO_MOVES_SUCCESSFUL,
  VIEW_FINANCEIRO_MOVES,
  VIEW_FINANCEIRO_MOVES_SUCCESSFUL,
  ACCOUNTS_FINANCEIRO_MOVES,
  ACCOUNTS_FINANCEIRO_MOVES_SUCCESSFUL,
} from './actionTypes';

const INIT_STATE = {
  error: '',
  moves: {
    data: {
      result: [],
      pagination: {
        start: 0,
        end: 0,
        total: 0,
      },
    },
    isLoading: true,
  },
  move: {
    data: {
      result: [],
    },
    isLoading: true,
  },
  accounts: {
    data: {
      result: [],
    },
    isLoading: true,
  },
  isSave: false,
};

const FinanceMoves = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTA_FINANCEIRO_MOVES:
      return {
        ...state,
        moves: {
          data: {
            result: [],
            pagination: {
              start: 0,
              end: 0,
              total: 0,
            },
          },
          isLoading: true,
        },
      };
    case LISTA_FINANCEIRO_MOVES_SUCCESSFUL:
      return {
        ...state,
        moves: {
          data: {
            result: action.payload.result,
            pagination: action.payload.pagination,
          },
          isLoading: false,
        },
      };
    case CADASTRAR_FINANCEIRO_MOVES:
      return {
        ...state,
        isSave: false,
      };
    case CADASTRAR_FINANCEIRO_MOVES_SUCCESSFUL:
      return {
        ...state,
        isSave: true,
      };
    case EDITAR_FINANCEIRO_MOVES:
      return {
        ...state,
        isSave: false,
      };
    case EDITAR_FINANCEIRO_MOVES_SUCCESSFUL:
      return {
        ...state,
        isSave: true,
      };
    case VIEW_FINANCEIRO_MOVES:
      return {
        ...state,
        move: {
          data: {
            result: [],
          },
          isLoading: true,
        },
      };
    case VIEW_FINANCEIRO_MOVES_SUCCESSFUL:
      return {
        ...state,
        move: {
          data: { result: action.payload },
          isLoading: false,
        },
      };
    case ACCOUNTS_FINANCEIRO_MOVES:
      return {
        ...state,
        accounts: {
          data: {
            result: [],
          },
          isLoading: true,
        },
      };
    case ACCOUNTS_FINANCEIRO_MOVES_SUCCESSFUL:
      return {
        ...state,
        accounts: {
          data: { result: action.payload.result },
          isLoading: false,
        },
      };
    default:
      return { ...state };
  }
};

export default FinanceMoves;
