import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  LISTA_FINANCEIRO_BANKSACCOUNTS,
  LISTA_FINANCEIRO_LISTBANKS,
  CADASTRAR_FINANCEIRO_BANKSACCOUNTS,
  EDITAR_FINANCEIRO_BANKSACCOUNTS,
  VIEW_FINANCEIRO_BANKSACCOUNTS,
  DELETAR_FINANCEIRO_BANKSACCOUNTS,
} from './actionTypes';
import {
  ListaFinanceiroBankAccountsSuccessful,
  ListaFinanceiroListBanksSuccessful,
  CadastrarFinanceiroBankAccountsSuccessful,
  EditarFinanceiroBankAccountsSuccessful,
  DeletarFinanceiroBankAccountsSuccessful,
  ViewFinanceiroBankAccountsSuccessful,
} from './actions';
import { apiError } from '../../actions';

// Include Both Helper File with needed methods
import { postJWT, getJWT } from '../../../helpers/JWTHelper';

function* GetFinanceiroBankAccounts({ payload: { search, page, total } }) {
  try {
    const response = yield call(
      getJWT,
      `financial/banksaccounts/list?search=${search}&page=${page}&total=${total}`
    );
    yield put(ListaFinanceiroBankAccountsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchListaFinanceiroBankAccounts() {
  yield takeEvery(LISTA_FINANCEIRO_BANKSACCOUNTS, GetFinanceiroBankAccounts);
}

function* GetFinanceiroListBanks({ payload: { search } }) {
  try {
    const response = yield call(
      getJWT,
      `financial/banks/list?search=${search}`
    );
    yield put(ListaFinanceiroListBanksSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchListaFinanceiroListBanks() {
  yield takeEvery(LISTA_FINANCEIRO_LISTBANKS, GetFinanceiroListBanks);
}

function* ViewFinanceiroBankAccounts({ payload: id }) {
  try {
    const response = yield call(getJWT, `financial/banksaccounts/view/${id}`);
    yield put(ViewFinanceiroBankAccountsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchViewFinanceiroBankAccounts() {
  yield takeEvery(VIEW_FINANCEIRO_BANKSACCOUNTS, ViewFinanceiroBankAccounts);
}

function* CadastrarFinanceiroBankAccounts({
  payload: { nome, titular, cpfcnpj, banco, agencia, conta, tipo },
}) {
  try {
    const response = yield call(postJWT, `financial/banksaccounts/create`, {
      nome,
      titular,
      cpfcnpj,
      banco,
      agencia,
      conta,
      tipo,
    });
    yield put(CadastrarFinanceiroBankAccountsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCadastrar() {
  yield takeEvery(
    CADASTRAR_FINANCEIRO_BANKSACCOUNTS,
    CadastrarFinanceiroBankAccounts
  );
}

function* EditarFinanceiroBankAccounts({
  payload: { id, nome, titular, cpfcnpj, banco, agencia, conta, tipo },
}) {
  try {
    // console.log(id, nome, titular, cpfcnpj, banco, agencia, conta, tipo);
    const response = yield call(postJWT, `financial/banksaccounts/update`, {
      id,
      nome,
      titular,
      cpfcnpj,
      banco,
      agencia,
      conta,
      tipo,
    });
    yield put(EditarFinanceiroBankAccountsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchEditar() {
  yield takeEvery(
    EDITAR_FINANCEIRO_BANKSACCOUNTS,
    EditarFinanceiroBankAccounts
  );
}

function* DeletarFinanceiroBankAccounts({ payload: id }) {
  try {
    const response = yield call(postJWT, `financial/banksaccounts/delete`, {
      id,
    });
    yield put(DeletarFinanceiroBankAccountsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchDeletar() {
  yield takeEvery(
    DELETAR_FINANCEIRO_BANKSACCOUNTS,
    DeletarFinanceiroBankAccounts
  );
}

function* FinanceBankAccountsSagas() {
  yield all([
    fork(watchListaFinanceiroBankAccounts),
    fork(watchListaFinanceiroListBanks),
    fork(watchViewFinanceiroBankAccounts),
    fork(watchCadastrar),
    fork(watchEditar),
    fork(watchDeletar),
  ]);
}

export default FinanceBankAccountsSagas;
