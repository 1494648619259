import {
  CHANGE_LOCALE,
  CATEGORY_PROFISSIONAL,
  CATEGORY_PROFISSIONAL_SUCCESSFUL,
  EDITAR_USER,
  EDITAR_USER_SUCCESSFUL,
  EDITAR_USER_FAILED,
  EDITAR_USER_PASS,
  EDITAR_USER_PASS_SUCCESSFUL,
  EDITAR_USER_PASS_FAILED,
} from './actionTypes';
// eslint-disable-next-line import/no-cycle
import { API_ERROR } from '../actions';
import { setCurrentLanguage } from '../../helpers/Utils';

// eslint-disable-next-line import/prefer-default-export
export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};

export const CategoryProfissional = () => {
  return {
    type: CATEGORY_PROFISSIONAL,
  };
};

export const CategoryProfissionalSuccessful = (dados) => {
  return {
    type: CATEGORY_PROFISSIONAL_SUCCESSFUL,
    payload: dados,
  };
};

export const EditarUser = (dados) => {
  return {
    type: EDITAR_USER,
    payload: dados,
  };
};

export const EditarUserSuccessful = (response) => {
  return {
    type: EDITAR_USER_SUCCESSFUL,
    payload: response,
  };
};

export const EditarUserFailed = (response) => {
  return {
    type: EDITAR_USER_FAILED,
    payload: response,
  };
};

export const EditarUserPass = (dados) => {
  return {
    type: EDITAR_USER_PASS,
    payload: dados,
  };
};

export const EditarUserPassSuccessful = (response) => {
  return {
    type: EDITAR_USER_PASS_SUCCESSFUL,
    payload: response,
  };
};

export const EditarUserPassFailed = (response) => {
  return {
    type: EDITAR_USER_PASS_FAILED,
    payload: response,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
