import {
  WPP_VERIFY,
  WPP_VERIFY_SUCCESSFUL,
  WPP,
  WPP_SUCCESSFUL,
} from './actionTypes';

export const WppVerify = () => {
  return {
    type: WPP_VERIFY,
  };
};

export const WppVerifySuccessful = (dados) => {
  return {
    type: WPP_VERIFY_SUCCESSFUL,
    payload: dados,
  };
};

export const WppActive = () => {
  return {
    type: WPP,
  };
};

export const WppActiveSuccessful = (dados) => {
  return {
    type: WPP_SUCCESSFUL,
    payload: dados,
  };
};
