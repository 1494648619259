import axios from 'axios';
import { servicePath } from '../constants/defaultValues';

const client = axios.create({ baseURL: servicePath });

const getToken = () => {
  return localStorage.getItem('@waydoctor-app/token');
};

const getJWT = async (endpoint) => {
  return client.get(endpoint, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};

const postJWT = async (endpoint, data) => {
  return client.post(endpoint, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};

const deleteJWT = async (endpoint) => {
  return client.delete(endpoint, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};

export { getJWT, postJWT, deleteJWT, getToken };
