import {
  VIEW_FINANCEIRO_CASHFLOW,
  VIEW_FINANCEIRO_CASHFLOW_SUCCESSFUL,
} from './actionTypes';

const INIT_STATE = {
  error: '',
  cashflow: {
    data: {
      result: {
        saldo: [],
        receitas: [],
        despesas: [],
        lucro_prejuizo: [],
        acumulado: [],
        lucratividade: [],
      },
    },
    isLoading: true,
  },
  isSave: false,
};

const FinanceCashFlow = (state = INIT_STATE, action) => {
  switch (action.type) {
    case VIEW_FINANCEIRO_CASHFLOW:
      return {
        ...state,
        cashflow: {
          data: {
            result: {
              saldo: [],
              receitas: [],
              despesas: [],
              lucro_prejuizo: [],
              acumulado: [],
              lucratividade: [],
            },
          },
          isLoading: true,
        },
      };
    case VIEW_FINANCEIRO_CASHFLOW_SUCCESSFUL:
      return {
        ...state,
        cashflow: {
          data: {
            result: {
              saldo: action.payload.result.saldo,
              receitas: action.payload.result.receitas,
              despesas: action.payload.result.despesas,
              lucro_prejuizo: action.payload.result.lucro_prejuizo,
              acumulado: action.payload.result.acumulado,
              lucratividade: action.payload.result.lucratividade,
            },
          },
          isLoading: false,
        },
      };
    default:
      return { ...state };
  }
};

export default FinanceCashFlow;
