import { all } from 'redux-saga/effects';
import authSagas from './auth/login/sagas';
import forgetPasswordSagas from './auth/forgot-password/sagas';
import SettingsSagas from './settings/sagas';
import DashboardSagas from './dashboard/sagas';
import FinanceExtractSagas from './financial/extract/sagas';
import CashFlowSagas from './financial/cashflow/sagas';
import MovesSagas from './financial/moves/sagas';
import RevenueSagas from './financial/revenue/sagas';
import ExpenditureSagas from './financial/expenditure/sagas';
import FinanceCategorySagas from './financial/category/sagas';
import FinanceCostCenterSagas from './financial/costcenter/sagas';
import FinanceBankAccountsSagas from './financial/bankaccounts/sagas';
import ConfigPlansSagas from './configs/plan/sagas';
import ConfigWppSagas from './configs/account/integration/sagas';

export default function* rootSaga() {
  yield all([
    authSagas(),
    SettingsSagas(),
    forgetPasswordSagas(),
    DashboardSagas(),
    FinanceExtractSagas(),
    CashFlowSagas(),
    MovesSagas(),
    RevenueSagas(),
    ExpenditureSagas(),
    FinanceCategorySagas(),
    FinanceCostCenterSagas(),
    FinanceBankAccountsSagas(),
    ConfigPlansSagas(),
    ConfigWppSagas(),
  ]);
}
