import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  LISTA_CONTAS_RECEBER,
  CATEGORIAS_CONTA_RECEBER,
  CAIXAS_CONTA_RECEBER,
  CENTROCUSTO_CONTA_RECEBER,
  PACIENTES_CONTA_RECEBER,
  CADASTRAR_CONTA_RECEBER,
  EDITAR_CONTA_RECEBER,
  VIEW_CONTA_RECEBER,
  DELETAR_CONTA_RECEBER,
} from './actionTypes';
import {
  ListaContasReceberSuccessful,
  CategoriasContasReceberSuccessful,
  CaixasContasReceberSuccessful,
  CentroCustoContasReceberSuccessful,
  PacientesContasReceberSuccessful,
  CadastrarContasReceberSuccessful,
  EditarContasReceberSuccessful,
  DeletarContasReceberSuccessful,
  ViewContasReceberSuccessful,
} from './actions';
import { apiError } from '../../actions';

// Include Both Helper File with needed methods
import { postJWT, getJWT } from '../../../helpers/JWTHelper';

function* GetContasReceber({
  payload: { search, page, total, datainicial, datafinal, status, categoria },
}) {
  try {
    const response = yield call(
      getJWT,
      `financial/billsreceive/list?search=${search}&page=${page}&total=${total}&datainicial=${datainicial}&datafinal=${datafinal}&status=${status}&categoria=${categoria}`
    );
    yield put(ListaContasReceberSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchListaContasReceber() {
  yield takeEvery(LISTA_CONTAS_RECEBER, GetContasReceber);
}

function* GetCategorias({ payload: search }) {
  try {
    const response = yield call(
      getJWT,
      `financial/billsreceive/categories?search=${search}`
    );
    yield put(CategoriasContasReceberSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCategories() {
  yield takeEvery(CATEGORIAS_CONTA_RECEBER, GetCategorias);
}

function* GetCaixas({ payload: search }) {
  try {
    const response = yield call(getJWT, `financial/accounts?search=${search}`);
    yield put(CaixasContasReceberSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCaixas() {
  yield takeEvery(CAIXAS_CONTA_RECEBER, GetCaixas);
}

function* GetCentroCustos({ payload: search }) {
  try {
    const response = yield call(
      getJWT,
      `financial/costcenter?search=${search}`
    );
    yield put(CentroCustoContasReceberSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCentroCustos() {
  yield takeEvery(CENTROCUSTO_CONTA_RECEBER, GetCentroCustos);
}

function* GetPacientes({ payload: search }) {
  try {
    const response = yield call(getJWT, `financial/patients?search=${search}`);
    yield put(PacientesContasReceberSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchPacientes() {
  yield takeEvery(PACIENTES_CONTA_RECEBER, GetPacientes);
}

function* ViewContasReceber({ payload: id }) {
  try {
    const response = yield call(getJWT, `financial/billsreceive/view/${id}`);
    yield put(ViewContasReceberSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchViewContasReceber() {
  yield takeEvery(VIEW_CONTA_RECEBER, ViewContasReceber);
}

function* CadastrarContasReceber({ payload: dados }) {
  try {
    const response = yield call(postJWT, `financial/billsreceive/create`, {
      id_paciente: dados.paciente,
      conta: dados.conta,
      categoria: dados.categoria,
      centrocusto: dados.centrocusto,
      pagamento: dados.pagamento,
      descricao: dados.descricao,
      informacoesextras: dados.observacoes,
      valor: dados.valor,
      vencimento: dados.vencimento,
      recorrencia: dados.recorrencia,
      recorrenciaquantidade: dados.recorrenciaquantidade,
      parcelas: dados.parcelas,
    });
    yield put(CadastrarContasReceberSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCadastrar() {
  yield takeEvery(CADASTRAR_CONTA_RECEBER, CadastrarContasReceber);
}

function* EditarContasReceber({ payload: dados }) {
  try {
    const response = yield call(postJWT, `financial/billsreceive/update`, {
      id: dados.id,
      id_paciente: dados.paciente,
      conta: dados.conta,
      categoria: dados.categoria,
      centrocusto: dados.centrocusto,
      pagamento: dados.pagamento,
      descricao: dados.descricao,
      informacoesextras: dados.observacoes,
      valor: dados.valor,
      vencimento: dados.vencimento,
      recorrencia: dados.recorrencia,
      recorrenciaquantidade: dados.recorrenciaquantidade,
      parcelas: dados.parcelas,
      status: dados.status,
    });
    yield put(EditarContasReceberSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchEditar() {
  yield takeEvery(EDITAR_CONTA_RECEBER, EditarContasReceber);
}

function* DeletarContasReceber({ payload: id }) {
  try {
    const response = yield call(postJWT, `financial/billsreceive/delete`, {
      id,
    });
    yield put(DeletarContasReceberSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchDeletar() {
  yield takeEvery(DELETAR_CONTA_RECEBER, DeletarContasReceber);
}

function* RevenueSagas() {
  yield all([
    fork(watchListaContasReceber),
    fork(watchCategories),
    fork(watchCaixas),
    fork(watchCentroCustos),
    fork(watchPacientes),
    fork(watchViewContasReceber),
    fork(watchCadastrar),
    fork(watchEditar),
    fork(watchDeletar),
  ]);
}

export default RevenueSagas;
