import {
  VIEW_FINANCEIRO_EXTRACT,
  VIEW_FINANCEIRO_EXTRACT_SUCCESSFUL,
  VIEW_FINANCEIRO_EXTRACT_ACCOUNTS,
  VIEW_FINANCEIRO_EXTRACT_ACCOUNTS_SUCCESSFUL,
  LIST_FINANCEIRO_EXTRACT,
  LIST_FINANCEIRO_EXTRACT_SUCCESSFUL,
  GRAPHIC_FINANCEIRO_EXTRACT,
  GRAPHIC_FINANCEIRO_EXTRACT_SUCCESSFUL,
} from './actionTypes';

export const ViewFinanceiroExtract = (
  search,
  status,
  receita,
  despesa,
  datainicial,
  datafinal,
  conta
) => {
  return {
    type: VIEW_FINANCEIRO_EXTRACT,
    payload: {
      search,
      status,
      receita,
      despesa,
      datainicial,
      datafinal,
      conta,
    },
  };
};

export const ViewFinanceiroExtractSuccessful = (dados) => {
  return {
    type: VIEW_FINANCEIRO_EXTRACT_SUCCESSFUL,
    payload: dados,
  };
};

export const ViewFinanceiroExtractAccounts = (
  search,
  status,
  receita,
  despesa,
  datainicial,
  datafinal,
  conta
) => {
  return {
    type: VIEW_FINANCEIRO_EXTRACT_ACCOUNTS,
    payload: {
      search,
      status,
      receita,
      despesa,
      datainicial,
      datafinal,
      conta,
    },
  };
};

export const ViewFinanceiroExtractAccountsSuccessful = (dados) => {
  return {
    type: VIEW_FINANCEIRO_EXTRACT_ACCOUNTS_SUCCESSFUL,
    payload: dados,
  };
};

export const ListaFinanceiroExtract = (
  search,
  status,
  receita,
  despesa,
  datainicial,
  datafinal,
  conta,
  page
) => {
  return {
    type: LIST_FINANCEIRO_EXTRACT,
    payload: {
      search,
      status,
      receita,
      despesa,
      datainicial,
      datafinal,
      conta,
      page,
    },
  };
};

export const ListaFinanceiroExtractSuccessful = (dados) => {
  return {
    type: LIST_FINANCEIRO_EXTRACT_SUCCESSFUL,
    payload: dados,
  };
};

export const GraphicFinanceiroExtract = (
  search,
  status,
  receita,
  despesa,
  datainicial,
  datafinal,
  conta,
  page
) => {
  return {
    type: GRAPHIC_FINANCEIRO_EXTRACT,
    payload: {
      search,
      status,
      receita,
      despesa,
      datainicial,
      datafinal,
      conta,
      page,
    },
  };
};

export const GraphicFinanceiroExtractSuccessful = (dados) => {
  return {
    type: GRAPHIC_FINANCEIRO_EXTRACT_SUCCESSFUL,
    payload: dados,
  };
};
