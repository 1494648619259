import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { LOGIN_USER, LOGOUT_USER, VERIFY_TOKEN_JWT } from './actionTypes';
import { loginSuccess, loginFailed, verifyTokenJwtSuccess } from './actions';
import { apiError } from '../../actions';
import { postJWT, getJWT } from '../../../helpers/JWTHelper';

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJWT, `login`, {
      email: user.email,
      password: user.password,
    });
    if (response.data.status) {
      yield put(loginSuccess(response.data));
      localStorage.setItem('@waydoctor-app/token', response.data.token);
      history.push('/dashboard');
    } else {
      yield put(loginFailed(response.data));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('@waydoctor-app/token');
    sessionStorage.clear();
    history.push('/log-in');
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* verifyTokenUser({ payload: { history } }) {
  try {
    const response = yield call(getJWT, `verify`, {});
    if (!response.data.status) {
      localStorage.removeItem('@waydoctor-app/token');
      sessionStorage.clear();
      history.push('/log-in');
    }
    yield put(verifyTokenJwtSuccess(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchVerifyToken() {
  yield takeEvery(VERIFY_TOKEN_JWT, verifyTokenUser);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchVerifyToken),
  ]);
}

export default authSaga;
