import {
  LISTA_FINANCEIRO_MOVES,
  LISTA_FINANCEIRO_MOVES_SUCCESSFUL,
  CADASTRAR_FINANCEIRO_MOVES,
  CADASTRAR_FINANCEIRO_MOVES_SUCCESSFUL,
  EDITAR_FINANCEIRO_MOVES,
  EDITAR_FINANCEIRO_MOVES_SUCCESSFUL,
  DELETAR_FINANCEIRO_MOVES,
  DELETAR_FINANCEIRO_MOVES_SUCCESSFUL,
  VIEW_FINANCEIRO_MOVES,
  VIEW_FINANCEIRO_MOVES_SUCCESSFUL,
  ACCOUNTS_FINANCEIRO_MOVES,
  ACCOUNTS_FINANCEIRO_MOVES_SUCCESSFUL,
} from './actionTypes';

export const ListaFinanceiroMoves = (search, page, total) => {
  return {
    type: LISTA_FINANCEIRO_MOVES,
    payload: { search, page, total },
  };
};

export const ListaFinanceiroMovesSuccessful = (dados) => {
  return {
    type: LISTA_FINANCEIRO_MOVES_SUCCESSFUL,
    payload: dados,
  };
};

export const CadastrarFinanceiroMoves = (dados) => {
  return {
    type: CADASTRAR_FINANCEIRO_MOVES,
    payload: dados,
  };
};

export const CadastrarFinanceiroMovesSuccessful = (response) => {
  return {
    type: CADASTRAR_FINANCEIRO_MOVES_SUCCESSFUL,
    payload: response,
  };
};

export const EditarFinanceiroMoves = (dados) => {
  return {
    type: EDITAR_FINANCEIRO_MOVES,
    payload: dados,
  };
};

export const EditarFinanceiroMovesSuccessful = (response) => {
  return {
    type: EDITAR_FINANCEIRO_MOVES_SUCCESSFUL,
    payload: response,
  };
};

export const DeletarFinanceiroMoves = (id) => {
  return {
    type: DELETAR_FINANCEIRO_MOVES,
    payload: id,
  };
};

export const DeletarFinanceiroMovesSuccessful = (response) => {
  return {
    type: DELETAR_FINANCEIRO_MOVES_SUCCESSFUL,
    payload: response,
  };
};

export const ViewFinanceiroMoves = (id) => {
  return {
    type: VIEW_FINANCEIRO_MOVES,
    payload: id,
  };
};

export const ViewFinanceiroMovesSuccessful = (dados) => {
  return {
    type: VIEW_FINANCEIRO_MOVES_SUCCESSFUL,
    payload: dados,
  };
};

export const AccountsFinanceiroMoves = () => {
  return {
    type: ACCOUNTS_FINANCEIRO_MOVES,
  };
};

export const AccountsFinanceiroMovesSuccessful = (dados) => {
  return {
    type: ACCOUNTS_FINANCEIRO_MOVES_SUCCESSFUL,
    payload: dados,
  };
};
