import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  LISTA_FINANCEIRO_MOVES,
  CADASTRAR_FINANCEIRO_MOVES,
  EDITAR_FINANCEIRO_MOVES,
  VIEW_FINANCEIRO_MOVES,
  DELETAR_FINANCEIRO_MOVES,
  ACCOUNTS_FINANCEIRO_MOVES,
} from './actionTypes';
import {
  ListaFinanceiroMovesSuccessful,
  CadastrarFinanceiroMovesSuccessful,
  EditarFinanceiroMovesSuccessful,
  DeletarFinanceiroMovesSuccessful,
  ViewFinanceiroMovesSuccessful,
  AccountsFinanceiroMovesSuccessful,
} from './actions';
import { apiError } from '../../actions';

// Include Both Helper File with needed methods
import { postJWT, getJWT } from '../../../helpers/JWTHelper';

function* GetFinanceiroMoves({ payload: { search, page, total } }) {
  try {
    const response = yield call(
      getJWT,
      `financial/moves/list?search=${search}&page=${page}&total=${total}`
    );
    yield put(ListaFinanceiroMovesSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchListaFinanceiroMoves() {
  yield takeEvery(LISTA_FINANCEIRO_MOVES, GetFinanceiroMoves);
}

function* ViewFinanceiroMoves({ payload: id }) {
  try {
    const response = yield call(getJWT, `financial/moves/view/${id}`);
    yield put(ViewFinanceiroMovesSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchViewFinanceiroMoves() {
  yield takeEvery(VIEW_FINANCEIRO_MOVES, ViewFinanceiroMoves);
}

function* CadastrarFinanceiroMoves({ payload: dados }) {
  try {
    const response = yield call(postJWT, `financial/moves/create`, {
      contasaida: dados.contasaida,
      contaentrada: dados.contaentrada,
      tipo: dados.tipo,
      lancamento: dados.lancamento,
      valor: dados.valor,
      observacoes: dados.observacoes,
    });
    yield put(CadastrarFinanceiroMovesSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCadastrar() {
  yield takeEvery(CADASTRAR_FINANCEIRO_MOVES, CadastrarFinanceiroMoves);
}

function* EditarFinanceiroMoves({ payload: dados }) {
  try {
    const response = yield call(postJWT, `financial/moves/update`, {
      contasaida: dados.contasaida,
      contaentrada: dados.contaentrada,
      tipo: dados.tipo,
      lancamento: dados.lancamento,
      valor: dados.valor,
      observacoes: dados.observacoes,
      id: dados.id,
    });
    yield put(EditarFinanceiroMovesSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchEditar() {
  yield takeEvery(EDITAR_FINANCEIRO_MOVES, EditarFinanceiroMoves);
}

function* DeletarFinanceiroMoves({ payload: id }) {
  try {
    const response = yield call(postJWT, `financial/moves/delete`, {
      id,
    });
    yield put(DeletarFinanceiroMovesSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchDeletar() {
  yield takeEvery(DELETAR_FINANCEIRO_MOVES, DeletarFinanceiroMoves);
}

function* AccountsFinanceiroMoves() {
  try {
    const response = yield call(getJWT, `financial/moves/accounts`);
    yield put(AccountsFinanceiroMovesSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchAccounts() {
  yield takeEvery(ACCOUNTS_FINANCEIRO_MOVES, AccountsFinanceiroMoves);
}

function* FinanceMovesSagas() {
  yield all([
    fork(watchListaFinanceiroMoves),
    fork(watchViewFinanceiroMoves),
    fork(watchCadastrar),
    fork(watchEditar),
    fork(watchDeletar),
    fork(watchAccounts),
  ]);
}

export default FinanceMovesSagas;
