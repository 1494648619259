import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_USER,
  LOGIN_VERIFY,
  LOGIN_VERIFY_SUCCESS,
  VERIFY_TOKEN_JWT_SUCCESS,
  VERIFY_TOKEN_JWT,
} from './actionTypes';

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginFailed = (user) => {
  return {
    type: LOGIN_FAILED,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const LoginVerify = (token) => {
  return {
    type: LOGIN_VERIFY,
    payload: { token },
  };
};

export const LoginVerifySuccess = (user) => {
  return {
    type: LOGIN_VERIFY_SUCCESS,
    payload: user,
  };
};

export const verifyTokenJwtSuccess = (user) => {
  return {
    type: VERIFY_TOKEN_JWT_SUCCESS,
    payload: user,
  };
};

export const VerifyTokenJwt = (history) => {
  return {
    type: VERIFY_TOKEN_JWT,
    payload: { history },
  };
};
