import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  LISTA_CONTAS_PAGAR,
  CATEGORIAS_CONTA_PAGAR,
  CAIXAS_CONTA_PAGAR,
  CENTROCUSTO_CONTA_PAGAR,
  PACIENTES_CONTA_PAGAR,
  CADASTRAR_CONTA_PAGAR,
  EDITAR_CONTA_PAGAR,
  VIEW_CONTA_PAGAR,
  DELETAR_CONTA_PAGAR,
} from './actionTypes';
import {
  ListaContasPagarSuccessful,
  CategoriasContasPagarSuccessful,
  CaixasContasPagarSuccessful,
  CentroCustoContasPagarSuccessful,
  PacientesContasPagarSuccessful,
  CadastrarContasPagarSuccessful,
  EditarContasPagarSuccessful,
  DeletarContasPagarSuccessful,
  ViewContasPagarSuccessful,
} from './actions';
import { apiError } from '../../actions';

// Include Both Helper File with needed methods
import { postJWT, getJWT } from '../../../helpers/JWTHelper';

function* GetContasPagar({
  payload: { search, page, total, datainicial, datafinal, status, categoria },
}) {
  try {
    const response = yield call(
      getJWT,
      `financial/billspay/list?search=${search}&page=${page}&total=${total}&datainicial=${datainicial}&datafinal=${datafinal}&status=${status}&categoria=${categoria}`
    );
    yield put(ListaContasPagarSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchListaContasPagar() {
  yield takeEvery(LISTA_CONTAS_PAGAR, GetContasPagar);
}

function* GetCategorias({ payload: search }) {
  try {
    const response = yield call(
      getJWT,
      `financial/billspay/categories?search=${search}`
    );
    yield put(CategoriasContasPagarSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCategories() {
  yield takeEvery(CATEGORIAS_CONTA_PAGAR, GetCategorias);
}

function* GetCaixas({ payload: search }) {
  try {
    const response = yield call(getJWT, `financial/accounts?search=${search}`);
    yield put(CaixasContasPagarSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCaixas() {
  yield takeEvery(CAIXAS_CONTA_PAGAR, GetCaixas);
}

function* GetCentroCustos({ payload: search }) {
  try {
    const response = yield call(
      getJWT,
      `financial/costcenter?search=${search}`
    );
    yield put(CentroCustoContasPagarSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCentroCustos() {
  yield takeEvery(CENTROCUSTO_CONTA_PAGAR, GetCentroCustos);
}

function* GetPacientes({ payload: search }) {
  try {
    const response = yield call(getJWT, `financial/patients?search=${search}`);
    yield put(PacientesContasPagarSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchPacientes() {
  yield takeEvery(PACIENTES_CONTA_PAGAR, GetPacientes);
}

function* ViewContasPagar({ payload: id }) {
  try {
    const response = yield call(getJWT, `financial/billspay/view/${id}`);
    yield put(ViewContasPagarSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchViewContasPagar() {
  yield takeEvery(VIEW_CONTA_PAGAR, ViewContasPagar);
}

function* CadastrarContasPagar({ payload: dados }) {
  try {
    const response = yield call(postJWT, `financial/billspay/create`, {
      id_paciente: dados.paciente,
      conta: dados.conta,
      categoria: dados.categoria,
      centrocusto: dados.centrocusto,
      pagamento: dados.pagamento,
      descricao: dados.descricao,
      informacoesextras: dados.observacoes,
      valor: dados.valor,
      vencimento: dados.vencimento,
      recorrencia: dados.recorrencia,
      recorrenciaquantidade: dados.recorrenciaquantidade,
      parcelas: dados.parcelas,
    });
    yield put(CadastrarContasPagarSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchCadastrar() {
  yield takeEvery(CADASTRAR_CONTA_PAGAR, CadastrarContasPagar);
}

function* EditarContasPagar({ payload: dados }) {
  try {
    const response = yield call(postJWT, `financial/billspay/update`, {
      id: dados.id,
      id_paciente: dados.paciente,
      conta: dados.conta,
      categoria: dados.categoria,
      centrocusto: dados.centrocusto,
      pagamento: dados.pagamento,
      descricao: dados.descricao,
      informacoesextras: dados.observacoes,
      valor: dados.valor,
      vencimento: dados.vencimento,
      recorrencia: dados.recorrencia,
      recorrenciaquantidade: dados.recorrenciaquantidade,
      parcelas: dados.parcelas,
      status: dados.status,
    });
    yield put(EditarContasPagarSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchEditar() {
  yield takeEvery(EDITAR_CONTA_PAGAR, EditarContasPagar);
}

function* DeletarContasPagar({ payload: id }) {
  try {
    const response = yield call(postJWT, `financial/billspay/delete`, {
      id,
    });
    yield put(DeletarContasPagarSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchDeletar() {
  yield takeEvery(DELETAR_CONTA_PAGAR, DeletarContasPagar);
}

function* ExpenditureSagas() {
  yield all([
    fork(watchListaContasPagar),
    fork(watchCategories),
    fork(watchCaixas),
    fork(watchCentroCustos),
    fork(watchPacientes),
    fork(watchViewContasPagar),
    fork(watchCadastrar),
    fork(watchEditar),
    fork(watchDeletar),
  ]);
}

export default ExpenditureSagas;
