import {
  LISTA_FINANCEIRO_CATEGORY,
  LISTA_FINANCEIRO_CATEGORY_SUCCESSFUL,
  CADASTRAR_FINANCEIRO_CATEGORY,
  CADASTRAR_FINANCEIRO_CATEGORY_SUCCESSFUL,
  EDITAR_FINANCEIRO_CATEGORY,
  EDITAR_FINANCEIRO_CATEGORY_SUCCESSFUL,
  DELETAR_FINANCEIRO_CATEGORY,
  DELETAR_FINANCEIRO_CATEGORY_SUCCESSFUL,
  VIEW_FINANCEIRO_CATEGORY,
  VIEW_FINANCEIRO_CATEGORY_SUCCESSFUL,
} from './actionTypes';

export const ListaFinanceiroCategory = (search, page, total, tipo) => {
  return {
    type: LISTA_FINANCEIRO_CATEGORY,
    payload: { search, page, total, tipo },
  };
};

export const ListaFinanceiroCategorySuccessful = (dados) => {
  return {
    type: LISTA_FINANCEIRO_CATEGORY_SUCCESSFUL,
    payload: dados,
  };
};

export const CadastrarFinanceiroCategory = (nome, tipo) => {
  return {
    type: CADASTRAR_FINANCEIRO_CATEGORY,
    payload: { nome, tipo },
  };
};

export const CadastrarFinanceiroCategorySuccessful = (response) => {
  return {
    type: CADASTRAR_FINANCEIRO_CATEGORY_SUCCESSFUL,
    payload: response,
  };
};

export const EditarFinanceiroCategory = (dados) => {
  return {
    type: EDITAR_FINANCEIRO_CATEGORY,
    payload: dados,
  };
};

export const EditarFinanceiroCategorySuccessful = (response) => {
  return {
    type: EDITAR_FINANCEIRO_CATEGORY_SUCCESSFUL,
    payload: response,
  };
};

export const DeletarFinanceiroCategory = (id) => {
  return {
    type: DELETAR_FINANCEIRO_CATEGORY,
    payload: id,
  };
};

export const DeletarFinanceiroCategorySuccessful = (response) => {
  return {
    type: DELETAR_FINANCEIRO_CATEGORY_SUCCESSFUL,
    payload: response,
  };
};

export const ViewFinanceiroCategory = (id) => {
  return {
    type: VIEW_FINANCEIRO_CATEGORY,
    payload: id,
  };
};

export const ViewFinanceiroCategorySuccessful = (dados) => {
  return {
    type: VIEW_FINANCEIRO_CATEGORY_SUCCESSFUL,
    payload: dados,
  };
};
