import { PLANS, PLANS_SUCCESSFUL } from './actionTypes';

export const PlansList = () => {
  return {
    type: PLANS,
  };
};

export const PlansListSuccessful = (dados) => {
  return {
    type: PLANS_SUCCESSFUL,
    payload: dados,
  };
};
