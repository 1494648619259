import {
  LISTA_FINANCEIRO_BANKSACCOUNTS,
  LISTA_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
  CADASTRAR_FINANCEIRO_BANKSACCOUNTS,
  CADASTRAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
  EDITAR_FINANCEIRO_BANKSACCOUNTS,
  EDITAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
  VIEW_FINANCEIRO_BANKSACCOUNTS,
  VIEW_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL,
  LISTA_FINANCEIRO_LISTBANKS,
  LISTA_FINANCEIRO_LISTBANKS_SUCCESSFUL,
} from './actionTypes';

const INIT_STATE = {
  error: '',
  bankaccounts: {
    data: {
      result: [],
      pagination: {
        start: 0,
        end: 0,
        total: 0,
      },
    },
    isLoading: true,
  },
  bankslist: {
    data: {
      result: [],
    },
    isLoading: true,
  },
  bankaccount: {
    data: {
      result: [],
    },
    isLoading: true,
  },
  isSave: false,
};

const FinanceBankAccounts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTA_FINANCEIRO_BANKSACCOUNTS:
      return {
        ...state,
        bankaccounts: {
          data: {
            result: [],
            pagination: {
              start: 0,
              end: 0,
              total: 0,
            },
          },
          isLoading: true,
        },
      };
    case LISTA_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL:
      return {
        ...state,
        bankaccounts: {
          data: {
            result: action.payload.result,
            pagination: action.payload.pagination,
          },
          isLoading: false,
        },
      };
    case LISTA_FINANCEIRO_LISTBANKS:
      return {
        ...state,
        bankslist: {
          data: {
            result: [],
          },
          isLoading: true,
        },
      };
    case LISTA_FINANCEIRO_LISTBANKS_SUCCESSFUL:
      return {
        ...state,
        bankslist: {
          data: { result: action.payload.result },
          isLoading: false,
        },
      };
    case CADASTRAR_FINANCEIRO_BANKSACCOUNTS:
      return {
        ...state,
        isSave: false,
      };
    case CADASTRAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL:
      return {
        ...state,
        isSave: true,
      };
    case EDITAR_FINANCEIRO_BANKSACCOUNTS:
      return {
        ...state,
        isSave: false,
      };
    case EDITAR_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL:
      return {
        ...state,
        isSave: true,
      };
    case VIEW_FINANCEIRO_BANKSACCOUNTS:
      return {
        ...state,
        bankaccount: {
          data: {
            result: [],
          },
          isLoading: true,
        },
      };
    case VIEW_FINANCEIRO_BANKSACCOUNTS_SUCCESSFUL:
      return {
        ...state,
        bankaccount: {
          data: { result: action.payload },
          isLoading: false,
        },
      };
    default:
      return { ...state };
  }
};

export default FinanceBankAccounts;
