import {
  VIEW_FINANCEIRO_CASHFLOW,
  VIEW_FINANCEIRO_CASHFLOW_SUCCESSFUL,
} from './actionTypes';

export const ViewFinanceiroCashFlow = (
  year,
  revenue,
  expenditure,
  vencidos,
  aberto,
  conta
) => {
  return {
    type: VIEW_FINANCEIRO_CASHFLOW,
    payload: { year, revenue, expenditure, vencidos, aberto, conta },
  };
};

export const ViewFinanceiroCashFlowSuccessful = (dados) => {
  return {
    type: VIEW_FINANCEIRO_CASHFLOW_SUCCESSFUL,
    payload: dados,
  };
};
