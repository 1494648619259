export const LISTA_CONTAS_RECEBER = 'LISTA_CONTAS_RECEBER';
export const LISTA_CONTAS_RECEBER_SUCCESSFUL =
  'LISTA_CONTAS_RECEBER_SUCCESSFUL';
export const CATEGORIAS_CONTA_RECEBER = 'CATEGORIAS_CONTA_RECEBER';
export const CATEGORIAS_CONTA_RECEBER_SUCCESSFUL =
  'CATEGORIAS_CONTA_RECEBER_SUCCESSFUL';
export const CAIXAS_CONTA_RECEBER = 'CAIXAS_CONTA_RECEBER';
export const CAIXAS_CONTA_RECEBER_SUCCESSFUL =
  'CAIXAS_CONTA_RECEBER_SUCCESSFUL';
export const CENTROCUSTO_CONTA_RECEBER = 'CENTROCUSTO_CONTA_RECEBER';
export const CENTROCUSTO_CONTA_RECEBER_SUCCESSFUL =
  'CENTROCUSTO_CONTA_RECEBER_SUCCESSFUL';
export const PACIENTES_CONTA_RECEBER = 'PACIENTES_CONTA_RECEBER';
export const PACIENTES_CONTA_RECEBER_SUCCESSFUL =
  'PACIENTES_CONTA_RECEBER_SUCCESSFUL';
export const CADASTRAR_CONTA_RECEBER = 'CADASTRAR_CONTA_RECEBER';
export const CADASTRAR_CONTA_RECEBER_SUCCESSFUL =
  'CADASTRAR_CONTA_RECEBER_SUCCESSFUL';
export const EDITAR_CONTA_RECEBER = 'EDITAR_CONTA_RECEBER';
export const EDITAR_CONTA_RECEBER_SUCCESSFUL =
  'EDITAR_CONTA_RECEBER_SUCCESSFUL';
export const DELETAR_CONTA_RECEBER = 'DELETAR_CONTA_RECEBER';
export const DELETAR_CONTA_RECEBER_SUCCESSFUL =
  'DELETAR_CONTA_RECEBER_SUCCESSFUL';
export const VIEW_CONTA_RECEBER = 'VIEW_CONTA_RECEBER';
export const VIEW_CONTA_RECEBER_SUCCESSFUL = 'VIEW_CONTA_RECEBER_SUCCESSFUL';
